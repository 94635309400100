import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import realEstateBanner from '../images/realEstateBanner3.png'
import realEstateSub from '../images/realEstateSub.png'
import { alpha } from '@mui/material/styles';
import { Typography, Grid, Box, Stack } from '@mui/material';
import StickySide from './StickySide';
import PrequalifyCard from './PrequalifyCard';
import StoreIcon from '@mui/icons-material/Store';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import GroupsIcon from '@mui/icons-material/Groups';
import HandshakeIcon from '@mui/icons-material/Handshake';

export default function RealEstate({ navBarHeight, footerHeight, open, setOpen, showNavbar }) {
    const theme = useTheme();


    const [isSticky, setIsSticky] = useState(false);
    const [stickyHeight, setStickyHeight] = useState(0);


    const updateSticky = () => {
        const offset = document.getElementById('image-container').offsetHeight;
        setIsSticky(window.scrollY > offset);
        //setStickyHeight(menuPosition - offset)
        setStickyHeight(showNavbar ? 568 - 400 : 50);
    };



    useEffect(() => {


        const handleResize = () => {
            updateSticky();
        };

        const handleScroll = () => {
            updateSticky();
        };

        // Add event listeners
        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        // Initial updates
        handleResize();
        updateSticky();

        return () => {
            // Clean up event listeners
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };

    }, [showNavbar]);


    const LeftContent = () => (
        <div>
            <Grid item xs={12} md={11}>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 600, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 24, sm: 28, md: 30 },
                }}>
                    Ready to expand your business footprint?
                </Typography>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 24 }
                }}>
                    Whether you're looking to purchase a new commercial property or refinance an existing one, our SBA 7(a) Commercial Real Estate Loans offer the flexibility and support you need. Designed with business owners in mind, our loans provide a reliable way to secure the space your business requires. With competitive rates and terms, we make it easier to invest in your future.
                    <br />
                    <br />
                    Our SBA 7(a) Commercial Real Estate Loans come with the following benefits:
                </Typography>
            </Grid>
            <br />
            <Grid item xs={12} md={11} sx={{ marginLeft: { xs: 0, sm: 0, md: 0 } }} >
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 600, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    marginBottom: 2,
                    fontSize: { xs: 20, sm: 22, md: 24 },
                }}>
                    <u>Long-Term Loans</u>
                </Typography>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 20 }
                }}>
                    <b>Up to 25 years:</b> Spread out your payments over a longer period, allowing for manageable monthly payments.
                </Typography>
                <br />
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 20 }
                }}>
                    <b>No balloon payments:</b> Keep your finances predictable with fully amortized loans. Don’t gamble your financial future on what credit conditions will be in 5 or 7 years. Our SBA 7(a) loans eliminate the uncertainty of balloon payments, providing you with stability and peace of mind throughout the life of your loan.
                </Typography>
                <br />
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 20 }
                }}>
                    <b>Lower Down Payments:</b> We typically require a down payment or equity of 10% although under certain circumstances 100% financing is available.
                </Typography>
            </Grid>
            <br />
            <br />
            <Grid item xs={12} md={11} sx={{ marginLeft: { xs: 0, sm: 0, md: 0 } }} >
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 600, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    marginBottom: 2,
                    fontSize: { xs: 20, sm: 22, md: 24 },
                }}>
                    <u>Flexible Financing Options</u>
                </Typography>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 20 }
                }}>
                    <b>Purchase or refinance:</b> Our loans cover both new acquisitions and refinancing of existing properties.
                </Typography>
                <br />
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 20 }
                }}>
                    <b>Construction financing:</b> Finance the construction of new commercial spaces or renovation of existing properties under the SBA 7(a) program.
                </Typography>
            </Grid>
            <br />
            <br />
            <PrequalifyCard />
            <br />
            <br />
            <Grid item xs={12} md={11}>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 600, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 24, sm: 28, md: 30 },
                }}>
                    Understanding the Process
                </Typography>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 24 }
                }}>
                    Navigating commercial real estate lending can be complex, but with the right information, you can approach the process with confidence. Here’s what you need to know:
                </Typography>
            </Grid>
            <br />
            <Grid item xs={12} md={11} sx={{ marginLeft: { xs: 0, sm: 0, md: 0 } }} >
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 600, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    marginBottom: -1,
                    fontSize: { xs: 18, sm: 20, md: 22 },
                }}>
                    <u>Property Eligibility</u>
                </Typography>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 20 }
                }}>
                    <ul><b>Owner-Occupied Requirement:</b> The property must be at least 51% owner-occupied for existing buildings and 60% for new construction.</ul>
                </Typography>
            </Grid>
            <br />
            <Grid item xs={12} md={11} sx={{ marginLeft: { xs: 0, sm: 0, md: 0 } }} >
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 600, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    marginBottom: -1,
                    fontSize: { xs: 18, sm: 20, md: 22 },
                }}>
                    <u>Loan Limits</u>
                </Typography>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 20 }
                }}>
                    <ul><b>Maximum Loan Amount:</b> The SBA 7(a) program allows for loan amounts up to $5 million, subject to SBA guidelines and lender criteria.</ul>
                    <ul><b>Loan-to-Value Ratio:</b> Typically, we finance up to 90% of the property value.</ul>
                </Typography>
            </Grid>
            <br/>
            <Grid item xs={12} md={11} sx={{ marginLeft: { xs: 0, sm: 0, md: 0 } }} >
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 600, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    marginBottom: -1,
                    fontSize: { xs: 18, sm: 20, md: 22 },
                }}>
                    <u>Pre-Approval</u>
                </Typography>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 20 }
                }}>
                    <ul><b>Pre-qualification process:</b> Get pre-approved to streamline your property search and negotiations.</ul>
                    <ul><b>Property Appraisal:</b> A professional appraisal is required to determine the property's market value.</ul>
                </Typography>
            </Grid>
            <br/>
            <Grid item xs={12} md={11} sx={{ marginLeft: { xs: 0, sm: 0, md: 0 } }} >
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 600, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    marginBottom: -1,
                    fontSize: { xs: 18, sm: 20, md: 22 },
                }}>
                    <u>Documentation Requirements</u>
                </Typography>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 20 }
                }}>
                    <ul><b>Purchase Agreement or Refinance Details:</b> Submit a purchase agreement for new acquisitions or provide detailed information on your existing mortgage if refinancing.</ul>
                    <ul><b>Financial Statements:</b> We require up-to-date financial statements for both your business and the property.</ul>
                    <ul><b>Environmental Reports:</b> Depending on the property type, you may need to submit an environmental report.</ul>
                </Typography>
            </Grid>
            <br/>
            <Grid item xs={12} md={11} sx={{ marginLeft: { xs: 0, sm: 0, md: 0 } }} >
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 600, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    marginBottom: -1,
                    fontSize: { xs: 18, sm: 20, md: 22 },
                }}>
                    <u>Site Visit</u>
                </Typography>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 20 }
                }}>
                    <ul><b>Property Inspection:</b> A site visit will be conducted as part of our due diligence process before closing.</ul>
                </Typography>
            </Grid>
            <br />
            <Grid item xs={12} md={11}>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 600, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 24, sm: 28, md: 30 },
                }}>
                    SBA Loan Prepayment Penalties
                </Typography>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 24 }
                }}>
                    For SBA 7(a) loans with a term of 15 years or more, there is typically a prepayment penalty if you pay off the loan within the first three years. The penalty is 5% of the prepaid amount in the first year, 3% in the second year, and 1% in the third year. This is crucial if you plan to refinance or sell the property soon after purchase.
                </Typography>
            </Grid>


        </div>

    )




    return (
        <div style={{ marginTop: '0rem' }}>
            <div id="image-container" style={{
                position: 'relative',
                marginTop: '40px',
                width: '100%'
            }}>
                <img
                    src={realEstateBanner}
                    alt='Business Expansion'
                    style={{
                        width: '100%',
                        maxHeight: '400px',
                        minHeight: '250px',
                        objectFit: 'cover',
                        objectPosition: 'center 70%',
                        display: 'block',  // Removes any extra space below the image
                    }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: { xs: '60px', sm: '80px', md: '100px' },
                        backgroundColor: alpha(theme.palette.primary.dark, 0.8),
                        zIndex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start', // Use 'flex-start' instead of 'left'
                    }}
                >
                    <Typography
                        component="span"
                        sx={{
                            color: 'white',
                            fontWeight: 600, // Adjust font weight as needed
                            textAlign: 'left',
                            marginLeft: 3,
                            fontSize: { xs: 24, sm: 34, md: 50 },
                            lineHeight: 1.1
                        }}
                    >
                        COMMERCIAL REAL ESTATE LOANS
                    </Typography>
                </Box>
            </div>
            <StickySide isSticky={isSticky} stickyHeight={stickyHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar}
                leftContent={<LeftContent />}
                leftSize='7'
                otherLoanUses={true} />

            <br />
            <br />
            <br />
            <br />
        </div >
    );
}




