import React, { useState, useEffect, useRef } from 'react';
import { useScroll, useTransform, motion, useMotionValueEvent } from 'framer-motion'
import { useTheme } from '@mui/material/styles';
import businessacquisitionfull from '../images/businessacquisitionfull.png'
import { alpha } from '@mui/material/styles';
import { Typography, Grid, useMediaQuery, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LearnMoreMediaCard from './LearnMoreMediaCard';
import LearnMoreButton from './LearnMoreButton'
import LearnMoreButtonDropDown from './LearnMoreButtonDropDown'
import HelpIcon from '@mui/icons-material/Help';
import SchoolIcon from '@mui/icons-material/School'
import AddTaskIcon from '@mui/icons-material/AddTask';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';

export default function StickySide({ navBarHeight, footerHeight, open, setOpen, isSticky, stickyHeight,
    showNavbar, leftContent, leftSize, otherLoanUses
}) {
    const theme = useTheme();
    const isXSmallScreen = useMediaQuery('(max-width:600px)');
    const isSmallScreen = useMediaQuery('(max-width:900px)');
    const isMediumScreen = useMediaQuery('(max-width:1200px)');

    const menuRef = useRef(null)
    const leftSideRef = useRef(null);




    return (
        <Grid container spacing={0} marginTop={8} justifyContent="center" alignItems="center"
            >
            <Grid ref={menuRef} item sm={12} md={4} spacing={2}
                sx={{
                    marginRight: { sm: 0, md: 6 },
                    marginTop: { sm: 10, md: 0 },
                    //position: 'relative',
                    //top: 'auto',
                    //right: 'auto',
                    //margin: isSticky ? '1rem' : '0',
                    //zIndex: 2, // Ensures HoverCard is above other content
                    //transition: 'top 0.35s ease, right 0.35s ease', // Smooth transition
                }}>
                <Grid container spacing={2} >  {/*  */}
                    <Grid item xs={12} sm={12} lg={12}>
                        <a href='../' style={{ textDecoration: 'none' }}>
                            <LearnMoreButton width={{ xs: 300, sm: 400, md: 240, lg: 400 }}
                                text='Home'
                                descr={(isMediumScreen && !isSmallScreen) || isXSmallScreen ? '' : 'Discover more on our Home Page'}
                                icon={<HomeIcon />}
                            />
                        </a>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12}>
                        <a href='../prequalify' style={{ textDecoration: 'none' }}>
                            <LearnMoreButton width={{ xs: 300, sm: 400, md: 240, lg: 400 }}
                                text='Prequalify'
                                descr={(isMediumScreen && !isSmallScreen) || isXSmallScreen ? '' : 'Get started in under 5 minutes'}
                                icon={<AddTaskIcon />}
                            />
                        </a>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12}>
                        <a href='../faq' style={{ textDecoration: 'none' }}>
                            <LearnMoreButton width={{ xs: 300, sm: 400, md: 240, lg: 400 }}
                                text='FAQs'
                                descr={(isMediumScreen && !isSmallScreen) || isXSmallScreen ? '' : 'Get answers to your SBA 7(a) questions'}
                                icon={<HelpIcon />}
                            />
                        </a>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12}>
                        <a href='../learning' style={{ textDecoration: 'none' }}>
                            <LearnMoreButton width={{ xs: 300, sm: 400, md: 240, lg: 400 }}
                                text='Learning Center'
                                descr={(isMediumScreen && !isSmallScreen) || isXSmallScreen ? '' : 'Expand Your Knowledge'}
                                icon={<SchoolIcon />}
                            />
                        </a>
                    </Grid>
                    {otherLoanUses ?
                        <Grid item xs={12} sm={12} lg={12} marginBottom={2}>
                            <LearnMoreButtonDropDown width={{ xs: 300, sm: 400, md: 240, lg: 400 }}
                                text='Explore Other Loan Uses'
                                icon={<SearchIcon />}
                                open={open}
                                setOpen={setOpen}
                            />
                        </Grid>
                        : <></>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}