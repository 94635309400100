import * as React from 'react';
import { Stack, Grid, Card, CardMedia, CardContent, Box, Typography } from '@mui/material';
import learning from '../images/learning.png'
import process from '../images/processcircle.png'
import questionmark from '../images/questionmark.png'
import clock from '../images/clock.png'
import sba from '../images/sba3.png'
import LearnMoreMediaCard from './LearnMoreMediaCard';
import TimelineIcon from '@mui/icons-material/Timeline';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import HelpIcon from '@mui/icons-material/Help';
import SchoolIcon from '@mui/icons-material/School';

export default function LearnMoreDetail() {
    return (
        <div style={{ marginTop: '0rem' }}>

            <Card style={{
                color: 'white',
                backgroundColor: 'transparent',
                boxShadow: 'none',
                padding: 0,
                marginTop: -10,
                height: 'auto',
                borderRadius: 0,
                position: 'relative', // Ensure relative positioning for absolute children
                overflow: 'hidden', // Clip overflowing content (like the image)
            }}>
                {/* Content within the card */}
                <div style={{ position: 'relative', zIndex: 1, textAlign: 'center' }}>
                    <Card sx={{
                        boxShadow: 'none',
                        bgcolor: 'transparent',
                    }}>
                        <br />
                        {/* <Stack direction="row" justifyContent="center" alignItems="center" spacing={3} marginBottom={10} marginTop={5} > */}
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}

                        >
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={12} md={10}>
                                    {/* Inside this Grid item, you can place your content */}
                                    <Grid container spacing={2}>
                                        {/* <Grid item xs={12} sm={12} lg={12}>
                                            <LearnMoreMediaCard image={sba} imagename="Standard" text={["Standard 7(a)"]} keyName="1" 
                                                                descr="Learn more about SBA's most popular program, Standard 7(a)"
                                                                buttonText=' about Standard 7(a)'/>
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={12}>
                                            <LearnMoreMediaCard image={clock} imagename="Express" text={["Express Lane 7(a)"]} keyName="2"
                                                                descr="Learn more about getting funded within days"
                                                                buttonText=' about Express Lane 7(a)'/>
                                        </Grid> */}
                                        <Grid item xs={12} sm={12} lg={12}>
                                        <a href='./process' style={{ textDecoration: 'none' }}>
                                            <LearnMoreMediaCard width={{ xs: 300, sm: 500, md: 400, lg: 500 }}
                                                image={process} imagename="Process" text={["Our Process"]} keyName="3"
                                                descr="Learn more about our process and what to expect from prequalification to funding"
                                                buttonText='OUR PROCESS'
                                                icon={<TimelineIcon />} />
                                                </a>
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={12}>
                                        <a href='./faq' style={{ textDecoration: 'none' }}>
                                            <LearnMoreMediaCard width={{ xs: 300, sm: 500, md: 400, lg: 500 }}
                                                image={questionmark} imagename="FAQs" text={["FAQs"]} keyName="4"
                                                descr="Get answers to SBA 7(a) frequently asked questions"
                                                buttonText='GET ANSWERS'
                                                icon={<HelpIcon />} />
                                                </a>
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={12} marginBottom={2}>
                                            <a href='./learning' style={{ textDecoration: 'none' }}>
                                                <LearnMoreMediaCard width={{ xs: 300, sm: 500, md: 400, lg: 500 }}
                                                    image={learning} imagename="Learning" text={["Learning Center"]} keyName="5"
                                                    descr="Expand your knowledge with resources from our Learning Center"
                                                    buttonText='LEARN MORE'
                                                    icon={<SchoolIcon />} />
                                            </a>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Card>
                </div>

            </Card>
            <br />
        </div>
    );
}


