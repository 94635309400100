import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import topimage from '../images/explained2.png'
import { alpha } from '@mui/material/styles';
import { Typography, Grid, Box, Stack } from '@mui/material';
import StickySide from './StickySide';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PolicyIcon from '@mui/icons-material/Policy';


export default function SBAExplained({ navBarHeight, footerHeight, open, setOpen, showNavbar }) {
    const theme = useTheme();


    const [isSticky, setIsSticky] = useState(false);
    const [stickyHeight, setStickyHeight] = useState(0);


    const updateSticky = () => {
        const offset = document.getElementById('image-container').offsetHeight;
        setIsSticky(window.scrollY > offset);
        //setStickyHeight(menuPosition - offset)
        setStickyHeight(showNavbar ? 568 - 400 : 50);
    };



    useEffect(() => {


        const handleResize = () => {
            updateSticky();
        };

        const handleScroll = () => {
            updateSticky();
        };

        // Add event listeners
        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        // Initial updates
        handleResize();
        updateSticky();

        return () => {
            // Clean up event listeners
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };

    }, [showNavbar]);

    const halfBackgroundStyle = {
        background: `linear-gradient(
    to top,
    transparent 0%, 
    transparent 10%, 
    ${theme.palette.primary.contrastText} 10%, 
    ${theme.palette.primary.contrastText} 25%, 
    transparent 25%, 
    transparent 100%
  )`,
        display: 'inline',
    };


    const LeftContent = () => (
        <div>
            <Grid item xs={12} md={10}>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 600, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 24, sm: 30, md: 40 },
                }}>
                    What is the SBA 7(a) Loan Program?
                </Typography>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 24 }
                }}>
                    The SBA 7(a) Loan Program is the <span style={halfBackgroundStyle}>most popular and versatile financing option</span> offered by the U.S. Small Business Administration (SBA). Since its creation in 1953, the program has played a critical role in supporting small businesses, helping them secure the funding they need to grow and thrive.
                </Typography>
            </Grid>
            <br />
            <Grid item xs={12} md={10} marginBottom={3}> {/* sx={{ marginLeft: { xs: 4, sm: 6, md: 10 } }} > */}
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 600, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 20 }
                }}>
                    How Does It Work?
                </Typography>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 17, sm: 20, md: 20 }
                }}>
                    The SBA itself doesn't lend money directly to businesses through the SBA 7(a) program. Instead, it collaborates with approved lenders like us. Here's how the process works:

                </Typography>
            </Grid>
            <Grid item xs={12} md={10} marginBottom={3}> {/* sx={{ marginLeft: { xs: 4, sm: 6, md: 10 } }} > */}
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: { xs: 3, sm: 6, md: 6, lg: 6 },
                    fontSize: { xs: 18, sm: 20, md: 20 }
                }}>
                    <b>Lender Partnership:</b> As your lending partner, we provide the actual funds for the loan. The SBA sets the guidelines and guarantees a portion of the loan—typically 75%—which reduces our risk and allows us to offer you more favorable loan terms, including <span style={halfBackgroundStyle}>lower interest rates</span> and <span style={halfBackgroundStyle}>longer repayment periods</span>.
                </Typography>
            </Grid>
            <Grid item xs={12} md={10} marginBottom={3}> {/* sx={{ marginLeft: { xs: 4, sm: 6, md: 10 } }} > */}
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: { xs: 3, sm: 6, md: 6, lg: 6 },
                    fontSize: { xs: 18, sm: 20, md: 20 }
                }}>
                    <b>Loan Process:</b> For a detailed overview of our loan process, please visit <a href="./process">Our Process</a>. In general, the journey starts with your prequalification, and here’s how we guide you through each step:
                </Typography>
            </Grid>
            <Grid item xs={12} md={10} marginBottom={3}>
                <Stack
                    direction="row"
                    spacing={2}
                    padding={2}
                    sx={{
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    <ArrowCircleUpIcon sx={{
                        color: theme.palette.primary.rainbow8,
                        fontSize: { xs: 35, sm: 40, md: 45 }
                    }} />
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 600, // Adjust font weight as needed
                        textAlign: 'left',
                        marginLeft: 3,
                        fontSize: { xs: 18, sm: 20, md: 25 }
                    }}>
                        Application Submission
                    </Typography>
                </Stack>

                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 17, sm: 20, md: 20 }
                }}>
                    Start by applying with us. Our streamlined online application process makes it easy to get started anytime, anywhere. You’ll need to provide key financial information about your business, including revenue, expenses, and existing debts.

                </Typography>
            </Grid>

            <Grid item xs={12} md={10} marginBottom={3}>
                <Stack
                    direction="row"
                    spacing={2}
                    padding={2}
                    sx={{
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    <ContentPasteSearchIcon sx={{
                        color: theme.palette.primary.rainbow9,
                        fontSize: { xs: 35, sm: 40, md: 45 }
                    }} />
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 600, // Adjust font weight as needed
                        textAlign: 'left',
                        marginLeft: 3,
                        fontSize: { xs: 18, sm: 20, md: 25 }
                    }}>
                        Business Evaluation
                    </Typography>
                </Stack>

                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 17, sm: 20, md: 20 }
                }}>
                    Once we receive your application, our team of experts evaluates your business’s financial health. This includes assessing your cash flow, credit history, and ability to repay the loan. We also ensure that your business meets all SBA requirements.

                </Typography>
            </Grid>

            <Grid item xs={12} md={10} marginBottom={3}>
                <Stack
                    direction="row"
                    spacing={2}
                    padding={2}
                    sx={{
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    <FactCheckIcon sx={{
                        color: theme.palette.primary.rainbow6,
                        fontSize: { xs: 35, sm: 40, md: 45 }
                    }} />
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 600, // Adjust font weight as needed
                        textAlign: 'left',
                        marginLeft: 3,
                        fontSize: { xs: 18, sm: 20, md: 25 }
                    }}>
                        SBA Paperwork Preparation
                    </Typography>
                </Stack>

                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 17, sm: 20, md: 20 }
                }}>
                    Navigating SBA loan paperwork can be complex, but we handle that for you. We prepare and submit all necessary documentation to the SBA, ensuring everything is completed accurately and on time.

                </Typography>
            </Grid>
            <Grid item xs={12} md={10} marginBottom={3}>
                <Stack
                    direction="row"
                    spacing={2}
                    padding={2}
                    sx={{
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    <ThumbUpIcon sx={{
                        color: theme.palette.primary.rainbow7,
                        fontSize: { xs: 35, sm: 40, md: 45 }
                    }} />
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 600, // Adjust font weight as needed
                        textAlign: 'left',
                        marginLeft: 3,
                        fontSize: { xs: 18, sm: 20, md: 25 }
                    }}>
                        Loan Approval and Closing
                    </Typography>
                </Stack>

                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 17, sm: 20, md: 20 }
                }}>
                    After the SBA reviews and approves the loan, we proceed to close the loan per SBA guidelines. This includes finalizing terms, securing guarantees, and disbursing the funds to your business.

                </Typography>
            </Grid>
            <Grid item xs={12} md={10} marginBottom={3}>
                <Stack
                    direction="row"
                    spacing={2}
                    padding={2}
                    sx={{
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    <AttachMoneyIcon sx={{
                        color: theme.palette.primary.rainbow10,
                        fontSize: { xs: 35, sm: 40, md: 45 }
                    }} />
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 600, // Adjust font weight as needed
                        textAlign: 'left',
                        marginLeft: 3,
                        fontSize: { xs: 18, sm: 20, md: 25 }
                    }}>
                        Payment Collection
                    </Typography>
                </Stack>

                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 17, sm: 20, md: 20 }
                }}>
                    We manage your loan payments, ensuring that the process is seamless and hassle-free.

                </Typography>
            </Grid>


            <Grid item xs={12} md={10} marginBottom={3}>
                <Stack
                    direction="row"
                    spacing={2}
                    padding={2}
                    sx={{
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    <PolicyIcon sx={{
                        color: theme.palette.primary.rainbow3,
                        fontSize: { xs: 35, sm: 40, md: 45 }
                    }} />
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 600, // Adjust font weight as needed
                        textAlign: 'left',
                        marginLeft: 3,
                        fontSize: { xs: 18, sm: 20, md: 25 }
                    }}>
                        Loan Monitoring
                    </Typography>
                </Stack>

                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 17, sm: 20, md: 20 }
                }}>
                    Our team stays engaged, monitoring your loan to ensure that all terms are met and offering assistance if any issues arise.

                </Typography>
            </Grid>

            <Grid item xs={12} md={10} marginBottom={3} paddingTop={6}> {/* sx={{ marginLeft: { xs: 4, sm: 6, md: 10 } }} > */}
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 600, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 25 }
                }}>
                    Still have questions?
                </Typography>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 17, sm: 20, md: 20 }
                }}>
                    Explore our <a href="./faq">FAQs</a> or visit our <a href="./learning">Learning Center</a> to dive deeper into everything you need to know about SBA 7(a) loans.

                </Typography>
            </Grid>

        </div>

    )




    return (
        <div style={{ marginTop: '0rem' }}>
            <div id="image-container" style={{
                position: 'relative',
                marginTop: '40px',
                width: '100%'
            }}>
                <img
                    src={topimage}
                    alt='Business Acquisition'
                    style={{
                        width: '100%',
                        maxHeight: '400px',
                        minHeight: '250px',
                        objectFit: 'cover',
                        objectPosition: 'center 40%',
                        display: 'block',  // Removes any extra space below the image
                    }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: { xs: '60px', sm: '80px', md: '100px' },
                        backgroundColor: alpha(theme.palette.primary.dark, 0.8),
                        zIndex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start', // Use 'flex-start' instead of 'left'
                    }}
                >
                    <Typography
                        component="span"
                        sx={{
                            color: 'white',
                            fontWeight: 600, // Adjust font weight as needed
                            textAlign: 'left',
                            marginLeft: 3,
                            fontSize: { xs: 24, sm: 34, md: 45 },
                            lineHeight: 1.1
                        }}
                    >
                        SBA 7(a) EXPLAINED
                    </Typography>
                </Box>
            </div>
            <StickySide isSticky={isSticky} stickyHeight={stickyHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar}
                leftContent={<LeftContent />}
                leftSize='7'
                otherLoanUses={false} />

            <br />
            <br />
            <br />
            <br />
        </div >
    );
}




