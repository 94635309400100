import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function MediaCard({ image, imagename, text, keyName, onClick, href }) {

    const [isHovered, setIsHovered] = React.useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <a href={href} style={{ textDecoration: 'none' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }} >
            
            <Card sx={{
                display: 'flex', maxWidth: 300, height: '100%',
                alignItems: 'center',
                transition: 'background-color 0.3s',
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: '#DFEBEF',
                    boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.2)',
                    opacity: '0.8',
                },
            }}
            //onClick={onClick}
            >
                <CardMedia 
                    sx={{
                        maxWidth: { xs: '30%', md: '40%' }, // Set the width of the image container
                        height: '100%', // Set the width of the image container
                        objectFit: 'cover', // Ensure the image covers the container
                    }}
                    component="img"
                    image={image}
                    alt={imagename}
                />
                <CardContent sx={{ alignItems: 'center', height: '100%', width: '100%', paddingBottom: '0  !important', padding: 0 }} >
                    <Typography variant="h4" fontWeight={550}
                        sx={{
                            fontSize: {
                                xs: '20px', // Adjust font size for extra-small screens (phones)
                                sm: '20px', // Adjust font size for small screens (tablets)
                                md: '17px', // Adjust font size for medium screens (small desktops)
                                lg: '22px', // Adjust font size for large screens (desktops)
                                xl: '24px', // Adjust font size for extra-large screens (large desktops and above)
                            },
                            color: 'primary.dark', textAlign: 'center', width: '100%',
                        }}>
                        {text}
                    </Typography>
                </CardContent>
            </Card> 
            
        </div>
        </a>
    );
}
