import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import veterinary from '../images/industryPics/veterinary1.png'
import { alpha } from '@mui/material/styles';
import { Typography, Grid, Box, useMediaQuery } from '@mui/material';
import StickySide from './StickySide';
import PrequalifyCard from './PrequalifyCard';
import MediaCardNoClick from './MediaCardNoClick'
import startimage from '../images/industryPics/startingVet.png'
import growingimage from '../images/industryPics/vetStaff.png'
import retiredimage from '../images/industryPics/vetRetired.png'

export default function BusinessAcquisition({ navBarHeight, footerHeight, open, setOpen, showNavbar }) {
    const theme = useTheme();


    const [isSticky, setIsSticky] = useState(false);
    const [stickyHeight, setStickyHeight] = useState(0);


    const updateSticky = () => {
        const offset = document.getElementById('image-container').offsetHeight;
        setIsSticky(window.scrollY > offset);
        //setStickyHeight(menuPosition - offset)
        setStickyHeight(showNavbar ? 568 - 400 : 50);
    };



    useEffect(() => {


        const handleResize = () => {
            updateSticky();
        };

        const handleScroll = () => {
            updateSticky();
        };

        // Add event listeners
        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        // Initial updates
        handleResize();
        updateSticky();

        return () => {
            // Clean up event listeners
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };

    }, [showNavbar]);


    const halfBackgroundStyle = {
        background: `linear-gradient(
    to top,
    transparent 0%, 
    transparent 10%, 
    ${theme.palette.primary.contrastText} 10%, 
    ${theme.palette.primary.contrastText} 25%, 
    transparent 25%, 
    transparent 100%
  )`,
        display: 'inline',
    };


    const LeftContent = () => (
        <div>
            <Grid item xs={12} md={10}>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 24 }
                }}>
                    The SBA 7(a) loan is a <span style={halfBackgroundStyle}>top financing choice</span> for veterinarians due to its flexibility and accessibility. Whether you're starting, expanding, or upgrading your practice, this program offers multiple advantages:
                </Typography>
            </Grid>
            <Grid item xs={10} md={8} sx={{ marginLeft: { xs: 4, sm: 6, md: 10 } }} >
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    fontSize: { xs: 17, sm: 20, md: 20 },
                    marginTop: '30px'
                }}>
                    <span><b>Longer Repayment Terms:</b> Repayment terms up to 25 years.</span>
                </Typography>
            </Grid>
            <Grid item xs={10} md={8} sx={{ marginLeft: { xs: 4, sm: 6, md: 10 } }} >
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    fontSize: { xs: 17, sm: 20, md: 20 },
                    marginTop: '30px'
                }}>
                    <span><b>Competitive Interest Rates:</b> Get a transparent and competitive interest rate estimate in writing in under 5 minutes with our quick, hassle-free <a href="../prequalify">prequalification</a> process.</span>
                </Typography>
            </Grid>
            <Grid item xs={10} md={8} sx={{ marginLeft: { xs: 4, sm: 6, md: 10 } }} >
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    fontSize: { xs: 17, sm: 20, md: 20 },
                    marginTop: '30px'
                }}>
                    <span><b>Lower Down Payments:</b> SBA 7(a) loans require less upfront capital compared to conventional loans, making it easier to afford necessary equipment or renovations.</span>
                </Typography>
            </Grid>
            <Grid item xs={10} md={8} sx={{ marginLeft: { xs: 4, sm: 6, md: 10 } }} >
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    fontSize: { xs: 17, sm: 20, md: 20 },
                    marginTop: '30px'
                }}>
                    <span><b>Flexible Use of Funds:</b> Use the loan for various needs, from purchasing equipment to refinancing existing debt.</span>
                </Typography>
            </Grid>
            <br />
            <Grid item xs={12} sm={11} md={10} sx={{ marginLeft: { xs: 1, sm: 1, md: 5 }, marginTop: 4 }} >
                <PrequalifyCard />
            </Grid>
            <br />
            <Grid item xs={12} md={10}>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 600, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: { xs: 2, sm: 2, md: 4 },
                    marginTop: 8,
                    fontSize: { xs: 24, sm: 30, md: 40 }
                }}>
                    What Can Veterinary Lending Do for You?
                </Typography>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: { xs: 2, sm: 2, md: 4 },
                    fontSize: { xs: 18, sm: 20, md: 24 }
                }}>
                    Our flexible financing solutions allow you to invest in the most critical aspects of your veterinary practice, whether it's one specific area or several at once:
                </Typography>
            </Grid>
            <br />
            <Grid item xs={10} md={8} sx={{ marginLeft: { xs: 4, sm: 6, md: 10 } }} >
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    fontSize: { xs: 17, sm: 20, md: 20 }
                }}>
                    <span><b>Clinic Expansions:</b> Open a new location or remodel your current space to better serve clients and improve patient care.</span>
                </Typography>
            </Grid>
            <Grid item xs={10} md={8} sx={{ marginLeft: { xs: 4, sm: 6, md: 10 } }} >
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    fontSize: { xs: 17, sm: 20, md: 20 },
                    marginTop: '30px'
                }}>
                    <span><b>Equipment & Technology:</b> Upgrade to cutting-edge diagnostic tools, surgical suites, or practice management software to enhance your services.</span>
                </Typography>
            </Grid>
            <Grid item xs={10} md={8} sx={{ marginLeft: { xs: 4, sm: 6, md: 10 } }} >
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    fontSize: { xs: 17, sm: 20, md: 20 },
                    marginTop: '30px'
                }}>
                    <span><b>Real Estate Investments:</b> Purchase, build, or refinance property to create the ideal clinic environment for your patients and staff.</span>
                </Typography>
            </Grid>
            <Grid item xs={10} md={8} sx={{ marginLeft: { xs: 4, sm: 6, md: 10 } }} >
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    fontSize: { xs: 17, sm: 20, md: 20 },
                    marginTop: '30px'
                }}>
                    <span><b>Working Capital:</b> Ensure smooth day-to-day operations by covering essential expenses like payroll, inventory, and marketing.</span>
                </Typography>
            </Grid>
            <Grid item xs={10} md={8} sx={{ marginLeft: { xs: 4, sm: 6, md: 10 } }} >
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    fontSize: { xs: 17, sm: 20, md: 20 },
                    marginTop: '30px'
                }}>
                    <span><b>Debt Refinancing:</b> Simplify your financial management by consolidating multiple loans into one, often with better terms.</span>
                </Typography>
            </Grid>
            
            <Grid item xs={12} md={10} marginTop={10}>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 600, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: { xs: 2, sm: 2, md: 4 },
                    fontSize: { xs: 24, sm: 30, md: 40 }
                }}>
                    Solutions for <span style={halfBackgroundStyle}>Every Stage</span> of Your Veterinary Career
                </Typography>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: { xs: 2, sm: 2, md: 4 },
                    fontSize: { xs: 18, sm: 20, md: 24 }
                }}>
                    Our SBA 7(a) loans are designed to support veterinarians throughout their careers, whether you’re just getting started, expanding your practice, or preparing for retirement.
                </Typography>
            </Grid>
            <Grid item xs={11} md={10} sx={{ marginLeft: { xs: 2, sm: 2, md: 4 }, marginTop: 6 }} >
                <MediaCardNoClick 
                    image={startimage} 
                    imagename='Starting Dentist'
                    title='Starting Up' 
                    descr='Launching a veterinary practice requires significant investment, from equipment to facility setup. With lower down payments and flexible terms, our SBA 7(a) loans make it easier to get the funding you need to build your practice from the ground up.'
                    isMediaLeft={true}
                    imagePosition='center'
                    height={{ xs: '380px', sm: '220px', md: '200px', lg: '180px'}}
                    />
            </Grid>
            <Grid item xs={11} md={10} sx={{ marginLeft: { xs: 2, sm: 2, md: 4 }, marginTop: 6 }} >
                <MediaCardNoClick 
                    image={growingimage} 
                    imagename='Growing Dentist'
                    title='Growing Your Practice' 
                    descr='As your practice grows, so do your needs. Whether you’re expanding your clinic, upgrading to the latest technology, or opening a new location, we provide the capital to help you scale effectively while maintaining cash flow with longer repayment terms.'
                    isMediaLeft={false}
                    imagePosition='center'
                    height={{ xs: '380px', sm: '220px', md: '200px', lg: '180px'}}
                    />
            </Grid>
            <Grid item xs={11} md={10} sx={{ marginLeft: { xs: 2, sm: 2, md: 4 }, marginTop: 6 }} >
                <MediaCardNoClick 
                    image={retiredimage} 
                    imagename='Retired Dentist'
                    title='Planning for Retirement' 
                    descr={<span>Preparing for retirement involves securing the future of your practice. We offer financing solutions that support practice transitions, such as funding for an acquirer or an Employee Stock Ownership Plan (<a href="../loanuses/esop">ESOP</a>).</span>}
                    isMediaLeft={true}
                    imagePosition='center left'
                    height={{ xs: '380px', sm: '220px', md: '200px', lg: '180px'}}
                    />
            </Grid>


        </div>

    )


    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const isMd = useMediaQuery(theme.breakpoints.down('md'));
    const isLg = useMediaQuery(theme.breakpoints.down('lg'));


    return (
        <div style={{ marginTop: '0rem' }}>
            <div id="image-container" style={{
                position: 'relative',
                marginTop: '40px',
                width: '100%'
            }}>
                <img
                    src={veterinary}
                    alt='Veterinarian'
                    style={{
                        width: '100%',
                        maxHeight: '400px',
                        minHeight: '250px',
                        objectFit: 'cover',
                        objectPosition: 'center 25%',
                        display: 'block',  // Removes any extra space below the image
                    }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: { xs: '60px', sm: '80px', md: '100px' },
                        backgroundColor: alpha(theme.palette.primary.dark, 0.8),
                        zIndex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start', // Use 'flex-start' instead of 'left'
                    }}
                >
                    <Typography
                        component="span"
                        sx={{
                            color: 'white',
                            fontWeight: 600, // Adjust font weight as needed
                            textAlign: 'left',
                            marginLeft: 3,
                            fontSize: { xs: 24, sm: 34, md: 50 },
                            lineHeight: 1.1
                        }}
                    >
                        VETERINARY LOANS
                    </Typography>
                </Box>
            </div>
            <StickySide isSticky={isSticky} stickyHeight={stickyHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar}
                leftContent={<LeftContent />}
                leftSize='7'
                otherLoanUses={false} />

            <br />
            <br />
            <br />
            <br />
        </div >
    );
}




