import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import expansionbanner from '../images/expansionbanner2.png'
import { alpha } from '@mui/material/styles';
import { Typography, Grid, Box, Stack } from '@mui/material';
import StickySide from './StickySide';
import PrequalifyCard from './PrequalifyCard';
import StoreIcon from '@mui/icons-material/Store';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import GroupsIcon from '@mui/icons-material/Groups';
import HandshakeIcon from '@mui/icons-material/Handshake';

export default function Expansion({ navBarHeight, footerHeight, open, setOpen, showNavbar }) {
    const theme = useTheme();


    const [isSticky, setIsSticky] = useState(false);
    const [stickyHeight, setStickyHeight] = useState(0);


    const updateSticky = () => {
        const offset = document.getElementById('image-container').offsetHeight;
        setIsSticky(window.scrollY > offset);
        //setStickyHeight(menuPosition - offset)
        setStickyHeight(showNavbar ? 568 - 400 : 50);
    };



    useEffect(() => {


        const handleResize = () => {
            updateSticky();
        };

        const handleScroll = () => {
            updateSticky();
        };

        // Add event listeners
        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        // Initial updates
        handleResize();
        updateSticky();

        return () => {
            // Clean up event listeners
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };

    }, [showNavbar]);


    const LeftContent = () => (
        <div>
            <Grid item xs={12} md={11}>

                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 24 }
                }}>
                    As your business thrives, the opportunity to expand becomes both exciting and essential. However, growth often requires a significant infusion of capital. Whether you're looking to open a new location, hire more staff, or invest in new equipment, the SBA 7(a) loan offers a reliable, flexible financing solution to help fuel your business expansion.
                </Typography>
            </Grid>
            <br />
            <Grid item xs={10} md={11} sx={{ marginLeft: { xs: 0, sm: 0, md: 0 } }} >
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 600, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 24, sm: 28, md: 30 },
                }}>
                    Why Choose an SBA 7(a) Loan for Expansion?
                </Typography>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 20 }
                }}>
                    The SBA 7(a) loan is designed specifically to support small business growth, offering a range of benefits to help you achieve your expansion goals.
                </Typography>
                <br />
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 20 }
                }}>
                    <b>Competitive Rates:</b> Receive a clear, competitive, written, interest rate estimate in less than 5 minutes with our easy <a href='../prequalify'>prequalification process</a>.
                </Typography>
                <br />
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 20 }
                }}>
                    <b>Favorable Terms:</b> SBA 7(a) loans offer repayment terms up to 25 years for real estate and 10 years for equipment, helping keep monthly payments manageable.
                </Typography>
                <br />
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 20 }
                }}>
                    <b>Minimal Down Payments:</b> Often, expansion financing through SBA 7(a) loans require no new equity.
                </Typography>
                <br />
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 20 }
                }}>
                    <b>Flexibility:</b> SBA 7(a) loans can be used for a wide variety of business needs, including expanding to new locations, acquiring competitors, or upgrading facilities.
                </Typography>
                <br />
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 20 }
                }}>
                    <b>High Loan Limits:</b> Borrow up to $5 million to finance everything from real estate to equipment and working capital.
                </Typography>


            </Grid>
            <br />
            <br />
            <PrequalifyCard />
            <br />
            <br />
            <Grid item xs={12} md={10}>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 600, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: { xs: 2, sm: 2, md: 4 },
                    fontSize: { xs: 24, sm: 28, md: 30 },
                    marginBottom: 2
                }}>
                    The Growth Support You Need
                </Typography>
                {/* <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 500, // Adjust font weight as needed
                        textAlign: 'left',
                        marginLeft: { xs: 2, sm: 2, md: 4 },
                        fontSize: { xs: 18, sm: 20, md: 20 }
                    }}>
                        <ul>
                            <li><b>Real Estate Acquisition:</b> Purchase a larger facility, buy your current building or open a new location.</li>
                            <li><b>Equipment & Inventory:</b> Fund essential purchases without straining cash flow.</li>
                            <li><b>Hiring:</b> Secure capital to onboard and train new staff.</li>
                            <li><b>Mergers & Acquisitions:</b> Facilitate strategic growth through acquisitions.</li>
                        </ul>
                    </Typography> */}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6} >
                        <Stack
                            direction="column"
                            spacing={0}
                            sx={{
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                marginBottom: 2
                            }}
                        >
                            <StoreIcon sx={{
                                color: theme.palette.primary.bright, fontSize: 50,
                                marginLeft: { xs: 2, sm: 2, md: 4 }
                            }} />
                            <Typography sx={{
                                color: theme.palette.secondary.light,
                                fontWeight: 500, // Adjust font weight as needed
                                textAlign: 'left',
                                marginLeft: { xs: 2, sm: 2, md: 4 },
                                fontSize: { xs: 18, sm: 20, md: 20 }
                            }}>
                                <b>Real Estate Acquisition</b>
                            </Typography>
                            <Typography sx={{
                                color: theme.palette.secondary.light,
                                fontWeight: 500, // Adjust font weight as needed
                                textAlign: 'left',
                                marginLeft: { xs: 2, sm: 2, md: 4 },
                                fontSize: { xs: 18, sm: 20, md: 20 }
                            }}>
                                Purchase a larger facility, buy your current building or open a new location.
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} >  
                        <Stack
                            direction="column"
                            spacing={0}
                            sx={{
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                marginBottom: 2
                            }}
                        >
                            <AgricultureIcon sx={{
                                color: theme.palette.primary.bright, fontSize: 50,
                                marginLeft: { xs: 2, sm: 2, md: 4 }
                            }} />
                            <Typography sx={{
                                color: theme.palette.secondary.light,
                                fontWeight: 500, // Adjust font weight as needed
                                textAlign: 'left',
                                marginLeft: { xs: 2, sm: 2, md: 4 },
                                fontSize: { xs: 18, sm: 20, md: 20 }
                            }}>
                                <b>Equipment & Inventory</b>
                            </Typography>
                            <Typography sx={{
                                color: theme.palette.secondary.light,
                                fontWeight: 500, // Adjust font weight as needed
                                textAlign: 'left',
                                marginLeft: { xs: 2, sm: 2, md: 4 },
                                fontSize: { xs: 18, sm: 20, md: 20 }
                            }}>
                                Fund essential purchases without straining cash flow.
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} >  
                        <Stack
                            direction="column"
                            spacing={0}
                            sx={{
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                marginBottom: 2
                            }}
                        >
                            <GroupsIcon sx={{
                                color: theme.palette.primary.bright, fontSize: 50,
                                marginLeft: { xs: 2, sm: 2, md: 4 }
                            }} />
                            <Typography sx={{
                                color: theme.palette.secondary.light,
                                fontWeight: 500, // Adjust font weight as needed
                                textAlign: 'left',
                                marginLeft: { xs: 2, sm: 2, md: 4 },
                                fontSize: { xs: 18, sm: 20, md: 20 }
                            }}>
                                <b>Hiring</b>
                            </Typography>
                            <Typography sx={{
                                color: theme.palette.secondary.light,
                                fontWeight: 500, // Adjust font weight as needed
                                textAlign: 'left',
                                marginLeft: { xs: 2, sm: 2, md: 4 },
                                fontSize: { xs: 18, sm: 20, md: 20 }
                            }}>
                                Secure capital to onboard and train new staff.
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} >  
                        <Stack
                            direction="column"
                            spacing={0}
                            sx={{
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                marginBottom: 2
                            }}
                        >
                            <HandshakeIcon sx={{
                                color: theme.palette.primary.bright, fontSize: 50,
                                marginLeft: { xs: 2, sm: 2, md: 4 }
                            }} />
                            <Typography sx={{
                                color: theme.palette.secondary.light,
                                fontWeight: 500, // Adjust font weight as needed
                                textAlign: 'left',
                                marginLeft: { xs: 2, sm: 2, md: 4 },
                                fontSize: { xs: 18, sm: 20, md: 20 }
                            }}>
                                <b>Mergers & Acquisitions</b>
                            </Typography>
                            <Typography sx={{
                                color: theme.palette.secondary.light,
                                fontWeight: 500, // Adjust font weight as needed
                                textAlign: 'left',
                                marginLeft: { xs: 2, sm: 2, md: 4 },
                                fontSize: { xs: 18, sm: 20, md: 20 }
                            }}>
                                Facilitate strategic growth through acquisitions.
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>


        </div>

    )




    return (
        <div style={{ marginTop: '0rem' }}>
            <div id="image-container" style={{
                position: 'relative',
                marginTop: '40px',
                width: '100%'
            }}>
                <img
                    src={expansionbanner}
                    alt='Business Expansion'
                    style={{
                        width: '100%',
                        maxHeight: '400px',
                        minHeight: '250px',
                        objectFit: 'cover',
                        objectPosition: 'center 30%',
                        display: 'block',  // Removes any extra space below the image
                    }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: { xs: '60px', sm: '80px', md: '100px' },
                        backgroundColor: alpha(theme.palette.primary.dark, 0.8),
                        zIndex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start', // Use 'flex-start' instead of 'left'
                    }}
                >
                    <Typography
                        component="span"
                        sx={{
                            color: 'white',
                            fontWeight: 600, // Adjust font weight as needed
                            textAlign: 'left',
                            marginLeft: 3,
                            fontSize: { xs: 24, sm: 34, md: 50 },
                            lineHeight: 1.1
                        }}
                    >
                        BUSINESS EXPANSION & GROWTH LOANS
                    </Typography>
                </Box>
            </div>
            <StickySide isSticky={isSticky} stickyHeight={stickyHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar}
                leftContent={<LeftContent />}
                leftSize='7'
                otherLoanUses={true} />

            <br />
            <br />
            <br />
            <br />
        </div >
    );
}




