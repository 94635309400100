import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography, Stack, Grid, useMediaQuery } from '@mui/material';
import PersonCard from './PersonCard'
import JerryCampbell from '../images/JerryCampbell2.jpg'
import DanaCluckey from '../images/DanaCluckey2.jpg'
import TomWelsh from '../images/TomWelsh2.jpg'
import { useTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { alpha } from '@mui/material/styles';

function About() {
    const theme = useTheme();

    const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const divMarginTop = isSmallScreen ? '90px' : '100px'

    console.log(isMediumScreen)

    return (
        <>
            <Helmet>
                <title>About CapTec USA</title>
                <meta name="description" content="CapTec USA is a leader in delivering innovative banking solutions tailored for small businesses nationwide. Welcome to modern business lending." />
                <link rel="canonical" href="https://thenewsouthbank.com/about" />
            </Helmet>
            <div style={{ marginTop: '0rem' }}>
                <div id="image-container" style={{
                    marginTop: divMarginTop,
                    width: '100%',

                }}>
                    <Stack
                        direction="column"
                        spacing={2}
                        sx={{
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: alpha(theme.palette.primary.dark, 0.9)
                        }}
                    >
                        <Typography
                            component="span"
                            sx={{
                                color: 'white',
                                fontWeight: 600, // Adjust font weight as needed
                                textAlign: 'center',
                                marginLeft: 3,
                                padding: 5,
                                fontSize: { xs: 30, sm: 40, md: 50 },
                                lineHeight: 1.1
                            }}
                        >
                            ABOUT CAPTEC USA
                        </Typography>
                    </Stack>
                </div>
                <div style={{ marginTop: '50px', marginLeft: '50px' }} className="Expect">
                    <Grid container>
                        <Grid item xs={11} sm={10} md={10} lg={10}>
                            <Stack
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                            >
                                <Stack>
                                    <Typography fontSize={40} fontWeight={500} color={theme.palette.primary.dark}    //blue
                                    //style={{ paddingBottom: '1rem' }} {/* Adjust padding to control distance from bottom */}
                                    >
                                        Company Mission
                                    </Typography>
                                    <br />
                                    <Typography fontSize={20} fontWeight={500} color={theme.palette.secondary.light}    //blue
                                    //style={{ paddingBottom: '1rem' }} {/* Adjust padding to control distance from bottom */}
                                    >
                                        Our mission is to make SBA 7(a) loans accessible, transparent, and efficient for small business owners nationwide. We are committed to breaking down the barriers that often make business financing feel complicated or out of reach. By simplifying the loan process, we empower entrepreneurs to focus on what they do best—growing their businesses.
                                        <br />
                                        <br />
                                        We believe that every business, regardless of size or industry, deserves access to the right financial tools. That’s why we provide financing solutions that are not only simple and clear but also tailored to the unique needs of each business. Whether you're expanding, launching a new venture, or stabilizing cash flow, we’re here to support your journey with personalized service and expert guidance.
                                        <br />
                                        <br />
                                        At the heart of everything we do is a deep commitment to the success of small businesses. We strive to be more than just a lender—we aim to be a trusted partner, helping you navigate the path to growth with confidence and ease.

                                    </Typography>
                                </Stack>
                                <br />
                                <br />
                                <br />
                                <Stack>
                                    <Typography fontSize={40} fontWeight={500} color={theme.palette.primary.dark}    //blue
                                    //style={{ paddingBottom: '1rem' }} {/* Adjust padding to control distance from bottom */}
                                    >
                                        Why Choose Us?
                                    </Typography>
                                    <br />
                                    <Typography fontSize={20} fontWeight={500} color={theme.palette.secondary.light}    //blue
                                    //style={{ paddingBottom: '1rem' }} {/* Adjust padding to control distance from bottom */}
                                    >
                                        <b>Speed</b>
                                        <br />
                                        Time is money, and we understand the urgency of getting your financing quickly. Our streamlined processes and efficient technology ensure that you receive prompt responses and swift loan decisions, helping you move forward with your business goals without unnecessary delays.
                                        <br />
                                        <br />
                                        <b>Personalized Service</b>
                                        <br />
                                        We believe that every business is unique, and your financing experience should reflect that. We work tirelessly to treat every customer like they are our only customer. Going above and beyond isn’t just a promise—it’s how we operate. We strive to provide a level of service that stands apart from typical banking experiences, ensuring you feel supported and valued every step of the way.
                                        <br />
                                        <br />
                                        <b>Expert Advice</b>
                                        <br />
                                        Navigating the world of SBA loans can be complex, but you don’t have to do it alone. Our team of experts is here to guide you through the process with personalized advice and insights tailored to your business needs. We’re committed to helping you make informed decisions that set your business up for success.

                                    </Typography>
                                </Stack>
                                <br />
                                <br />
                                <Box sx={{
                                    width: '70vw',
                                    height: '5rem',
                                    borderBottom: 1,
                                    textAlign: 'left',
                                    m: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-end',
                                    marginLeft: '-30rem',
                                }}>
                                    <Typography fontSize={30} fontWeight={500} color={theme.palette.primary.dark}    //blue
                                    //style={{ paddingBottom: '1rem' }} {/* Adjust padding to control distance from bottom */}
                                    >
                                        Meet the Team
                                    </Typography>
                                </Box>
                                <br />
                                <Grid container spacing={0} sx={{ width: { xs: '80vw', md: '70vw' } }}>
                                    <Grid item xs={12} sm={8} md={6} lg={5}
                                        sx={{
                                            margin: {
                                                xs: '24px 24px 24px -16px',   // (top right bottom left)
                                                sm: '24px 24px 24px 8px',
                                                md: '24px 24px 24px 24px',
                                            },
                                        }}>
                                        <PersonCard image={JerryCampbell}
                                            name='Jerry Campbell'
                                            descr={["Chairman,", <br />, "President and CEO"]}
                                            bio='Jerry was formerly the Chairman and Chief Executive Officer of HomeBancorp, Inc., a $1 billion in assets bank in Tampa, Florida. He previously served as Chairman of the Board of Republic Bancorp Inc. since the Company’s founding in 1986. Republic Bancorp Inc., with over $6 billion in assets, was the second largest bank holding company headquartered in Michigan. Jerry has a B.S. degree in liberal arts from Central Michigan University, a M.B.A. degree from Wayne State University, and a M.B.A. degree from the University of Michigan.'
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={6} lg={5}
                                        sx={{
                                            margin: {
                                                xs: '24px 24px 24px -16px',   // (top right bottom left)
                                                sm: '24px 24px 24px 8px',
                                                md: '24px 24px 24px 24px',
                                            },
                                        }}>
                                        <PersonCard image={DanaCluckey}
                                            name='Dana Cluckey'
                                            descr={["Vice Chairman"]}
                                            bio='Dana was formerly Vice Chairman of the Board, President and Chief Operating Officer of HomeBancorp, Inc., a $1 billion in assets bank in Tampa, Florida. He previously served as President and Chief Operating Officer and a member of the Board of Directors of Citizens Republic Bancorp Inc. Prior to that, he served in various roles, including Chief Executive Officer, and a director, with Republic Bancorp Inc. Dana is a Certified Public Accountant and has a B.B.A. degree from the University of Michigan.'
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={6} lg={5}
                                        sx={{
                                            margin: {
                                                xs: '24px 24px 24px -16px',   // (top right bottom left)
                                                sm: '24px 24px 24px 8px',
                                                md: '24px 24px 24px 24px',
                                            },
                                        }}>
                                        <PersonCard image={TomWelsh}
                                            name='Tom Welsh'
                                            descr={["EVP, CFO"]}
                                            icon={<LinkedInIcon />}
                                            link='https://www.linkedin.com/in/tomwelsh1/'
                                            bio='Tom was formerly Chief Financial Officer for TCM Bank in Tampa, Florida. Prior to that, he was Senior Vice President, Controller and Chief Accounting Officer for HomeBanc, the banking subsidiary of HomeBancorp, Inc. Tom is a Certified Public Accountant and CFA Charter Holder. He has a B.S. degree from Florida State University and a M.S. degree from the University of Notre Dame.'
                                        />
                                    </Grid>

                                </Grid>
                                <br />
                                <br />
                                <br />
                            </Stack>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}

export default About;
