import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import refinancebanner from '../images/refinancebanner.png'
import { alpha } from '@mui/material/styles';
import { Typography, Grid, Box, Stack, useMediaQuery } from '@mui/material';
import StickySide from './StickySide';
import PrequalifyCard from './PrequalifyCard';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SavingsIcon from '@mui/icons-material/Savings';
import GavelIcon from '@mui/icons-material/Gavel';

export default function Expansion({ navBarHeight, footerHeight, open, setOpen, showNavbar }) {
    const theme = useTheme();


    const [isSticky, setIsSticky] = useState(false);
    const [stickyHeight, setStickyHeight] = useState(0);


    const updateSticky = () => {
        const offset = document.getElementById('image-container').offsetHeight;
        setIsSticky(window.scrollY > offset);
        //setStickyHeight(menuPosition - offset)
        setStickyHeight(showNavbar ? 568 - 400 : 50);
    };



    useEffect(() => {


        const handleResize = () => {
            updateSticky();
        };

        const handleScroll = () => {
            updateSticky();
        };

        // Add event listeners
        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        // Initial updates
        handleResize();
        updateSticky();

        return () => {
            // Clean up event listeners
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };

    }, [showNavbar]);

    const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'));
    const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const isXSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'));

    const LeftContent = () => (
        <div>
            <Grid item xs={12} md={11}>

                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 600, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 20, sm: 24, md: 24 }
                }}>
                    {isSmallScreen
                        ? <span>
                            Refinancing Business Debt
                            <br />with an SBA 7(a) Loan:
                            <br />
                            <span style={{ display: 'block', marginTop: '0.5em' }}>
                                Unlock Better Financial Terms
                            </span>
                            for Your Business
                        </span>
                        :
                        isMediumScreen
                            ? <span>
                                Refinancing Business Debt with an SBA 7(a) Loan:
                                <br />Unlock Better Financial Terms for Your Business
                            </span>
                            : isLargeScreen
                                ? <span>
                                    Refinancing Business Debt
                                    <br />with an SBA 7(a) Loan:
                                    <br />
                                    <span style={{ display: 'block', marginTop: '0.5em' }}>
                                        Unlock Better Financial Terms
                                    </span>
                                    for Your Business
                                </span>
                                : <span>
                                    Refinancing Business Debt with an SBA 7(a) Loan:
                                    <br />Unlock Better Financial Terms for Your Business
                                </span>
                    }

                </Typography>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    marginTop: 2,
                    fontSize: { xs: 17, sm: 18, md: 20 }
                }}>
                    Managing business debt can be challenging, especially when high-interest loans or restrictive terms start to strain your cash flow. If you're looking for a way to improve your financial situation, refinancing with an SBA 7(a) loan might be the solution. These loans offer attractive terms, lower interest rates, and the potential to consolidate multiple debts into one manageable payment, freeing up more resources to invest back into your business.
                </Typography>
            </Grid>
            <br />
            <Grid item xs={12} md={11} sx={{ marginLeft: { xs: 0, sm: 0, md: 0 } }} >
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 600, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 20, sm: 24, md: 24 },
                }}>
                    Why Refinance with an SBA 7(a) Loan?
                </Typography>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 20 }
                }}>
                    Refinancing with an SBA 7(a) loan can offer several key benefits:
                </Typography>
                <br />
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 20 }
                }}>
                    <b>Lower Monthly Payments:</b> By refinancing at a lower interest rate or extending the loan term, your monthly payments can be significantly reduced, improving your cash flow and enabling you to reinvest in your business.
                </Typography>
                <br />
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 20 }
                }}>
                    <b>Consolidate Debt:</b> If you have multiple loans with varying terms, consolidating them into a single SBA 7(a) loan can simplify your financial obligations and reduce the stress of managing multiple payments.
                </Typography>
                <br />
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 20 }
                }}>
                    <b>Access Better Terms:</b> Many business owners find themselves trapped in high-interest loans or loans with unfavorable terms. Refinancing with an SBA 7(a) loan allows you to replace these loans with one that offers more flexibility and better terms.
                </Typography>
                <br />
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 20 }
                }}>
                    <b>Stabilize Cash Flow:</b> With a longer repayment term and lower interest rates, SBA 7(a) loans can help stabilize your cash flow, making it easier to plan for the future and grow your business
                </Typography>


            </Grid>
            <br />
            <br />
            <PrequalifyCard />
            <br />
            <br />
            <Grid item xs={12} md={11}>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 600, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: { xs: 2, sm: 2, md: 4 },
                    fontSize: { xs: 24, sm: 28, md: 30 },
                    marginBottom: 2
                }}>
                    Types of Debt Eligible for Refinancing
                </Typography>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: { xs: 2, sm: 2, md: 4 },
                    fontSize: { xs: 18, sm: 20, md: 20 }
                }}>
                    The SBA 7(a) loan can be used to refinance a variety of business debts, including:
                </Typography>
                {/* <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 500, // Adjust font weight as needed
                        textAlign: 'left',
                        marginLeft: { xs: 2, sm: 2, md: 4 },
                        fontSize: { xs: 18, sm: 20, md: 20 }
                    }}>
                        <ul>
                            <li><b>Real Estate Acquisition:</b> Purchase a larger facility, buy your current building or open a new location.</li>
                            <li><b>Equipment & Inventory:</b> Fund essential purchases without straining cash flow.</li>
                            <li><b>Hiring:</b> Secure capital to onboard and train new staff.</li>
                            <li><b>Mergers & Acquisitions:</b> Facilitate strategic growth through acquisitions.</li>
                        </ul>
                    </Typography> */}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6} >
                        <Stack
                            direction="column"
                            spacing={0}
                            sx={{
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                marginBottom: 2
                            }}
                        >
                            <TrendingUpIcon sx={{
                                color: theme.palette.primary.bright, fontSize: 50,
                                marginLeft: { xs: 2, sm: 2, md: 4 }
                            }} />
                            <Typography sx={{
                                color: theme.palette.secondary.light,
                                fontWeight: 500, // Adjust font weight as needed
                                textAlign: 'left',
                                marginLeft: { xs: 2, sm: 2, md: 4 },
                                fontSize: { xs: 18, sm: 20, md: 20 }
                            }}>
                                <b>High-Interest Loans</b>
                            </Typography>
                            <Typography sx={{
                                color: theme.palette.secondary.light,
                                fontWeight: 500, // Adjust font weight as needed
                                textAlign: 'left',
                                marginLeft: { xs: 2, sm: 2, md: 4 },
                                fontSize: { xs: 18, sm: 20, md: 20 }
                            }}>
                                If your current loan has an interest rate higher than the SBA maximum allowable rate, refinancing can reduce your interest payments.
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} >
                        <Stack
                            direction="column"
                            spacing={0}
                            sx={{
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                marginBottom: 2
                            }}
                        >
                            <CreditCardIcon sx={{
                                color: theme.palette.primary.bright, fontSize: 50,
                                marginLeft: { xs: 2, sm: 2, md: 4 }
                            }} />
                            <Typography sx={{
                                color: theme.palette.secondary.light,
                                fontWeight: 500, // Adjust font weight as needed
                                textAlign: 'left',
                                marginLeft: { xs: 2, sm: 2, md: 4 },
                                fontSize: { xs: 18, sm: 20, md: 20 }
                            }}>
                                <b>Credit Card Debt</b>
                            </Typography>
                            <Typography sx={{
                                color: theme.palette.secondary.light,
                                fontWeight: 500, // Adjust font weight as needed
                                textAlign: 'left',
                                marginLeft: { xs: 2, sm: 2, md: 4 },
                                fontSize: { xs: 18, sm: 20, md: 20 }
                            }}>
                                Business credit card debt can be refinanced, provided it was used exclusively for business purposes.
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} >
                        <Stack
                            direction="column"
                            spacing={0}
                            sx={{
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                marginBottom: 2
                            }}
                        >
                            <SavingsIcon sx={{
                                color: theme.palette.primary.bright, fontSize: 50,
                                marginLeft: { xs: 2, sm: 2, md: 4 }
                            }} />
                            <Typography sx={{
                                color: theme.palette.secondary.light,
                                fontWeight: 500, // Adjust font weight as needed
                                textAlign: 'left',
                                marginLeft: { xs: 2, sm: 2, md: 4 },
                                fontSize: { xs: 18, sm: 20, md: 20 }
                            }}>
                                <b>Over-Collateralized Loans</b>
                            </Typography>
                            <Typography sx={{
                                color: theme.palette.secondary.light,
                                fontWeight: 500, // Adjust font weight as needed
                                textAlign: 'left',
                                marginLeft: { xs: 2, sm: 2, md: 4 },
                                fontSize: { xs: 18, sm: 20, md: 20 }
                            }}>
                                Loans secured by more collateral than necessary can be refinanced to free up assets for other uses.
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} >
                        <Stack
                            direction="column"
                            spacing={0}
                            sx={{
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                marginBottom: 2
                            }}
                        >
                            <GavelIcon sx={{
                                color: theme.palette.primary.bright, fontSize: 50,
                                marginLeft: { xs: 2, sm: 2, md: 4 }
                            }} />
                            <Typography sx={{
                                color: theme.palette.secondary.light,
                                fontWeight: 500, // Adjust font weight as needed
                                textAlign: 'left',
                                marginLeft: { xs: 2, sm: 2, md: 4 },
                                fontSize: { xs: 18, sm: 20, md: 20 }
                            }}>
                                <b>Demand or Balloon Payments</b>
                            </Typography>
                            <Typography sx={{
                                color: theme.palette.secondary.light,
                                fontWeight: 500, // Adjust font weight as needed
                                textAlign: 'left',
                                marginLeft: { xs: 2, sm: 2, md: 4 },
                                fontSize: { xs: 18, sm: 20, md: 20 }
                            }}>
                                Loans with large, lump-sum payments due at maturity can be refinanced into a loan with a more manageable payment structure.
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
                <br/>
                <br/>
                <Grid item xs={12} md={11}>
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 600, // Adjust font weight as needed
                        textAlign: 'left',
                        marginLeft: { xs: 2, sm: 2, md: 4 },
                        fontSize: { xs: 24, sm: 28, md: 30 },
                        marginBottom: 2
                    }}>
                        Conditions for Refinancing
                    </Typography>
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 500, // Adjust font weight as needed
                        textAlign: 'left',
                        marginLeft: { xs: 2, sm: 2, md: 4 },
                        fontSize: { xs: 18, sm: 20, md: 20 }
                    }}>
                        <b>Current Status of Debt:</b> The debt to be refinanced must be current, meaning no required payment has remained unpaid for more than 29 days within the last 12 months, or for the life of the loan if it is less than 12 months.
                    </Typography>
                </Grid>
            </Grid>



        </div>

    )




    return (
        <div style={{ marginTop: '0rem' }}>
            <div id="image-container" style={{
                position: 'relative',
                marginTop: '40px',
                width: '100%'
            }}>
                <img
                    src={refinancebanner}
                    alt='Debt Refinance'
                    style={{
                        width: '100%',
                        maxHeight: '400px',
                        minHeight: '250px',
                        objectFit: 'cover',
                        objectPosition: 'center 40%',
                        display: 'block',  // Removes any extra space below the image
                    }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: { xs: '60px', sm: '80px', md: '100px' },
                        backgroundColor: alpha(theme.palette.primary.dark, 0.8),
                        zIndex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start', // Use 'flex-start' instead of 'left'
                    }}
                >
                    <Typography
                        component="span"
                        sx={{
                            color: 'white',
                            fontWeight: 600, // Adjust font weight as needed
                            textAlign: 'left',
                            marginLeft: 3,
                            fontSize: { xs: 24, sm: 34, md: 50 },
                            lineHeight: 1.1
                        }}
                    >
                        DEBT REFINANCING
                    </Typography>
                </Box>
            </div>
            <StickySide isSticky={isSticky} stickyHeight={stickyHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar}
                leftContent={<LeftContent />}
                leftSize='7'
                otherLoanUses={true} />

            <br />
            <br />
            <br />
            <br />
        </div >
    );
}




