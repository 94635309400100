import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import businessacquisitionfull from '../images/businessacquisitionfull.png'
import { alpha } from '@mui/material/styles';
import { Typography, Grid, Box } from '@mui/material';
import StickySide from './StickySide';
import PrequalifyCard from './PrequalifyCard';


export default function BusinessAcquisition({ navBarHeight, footerHeight, open, setOpen, showNavbar }) {
    const theme = useTheme();


    const [isSticky, setIsSticky] = useState(false);
    const [stickyHeight, setStickyHeight] = useState(0);


    const updateSticky = () => {
        const offset = document.getElementById('image-container').offsetHeight;
        setIsSticky(window.scrollY > offset);
        //setStickyHeight(menuPosition - offset)
        setStickyHeight(showNavbar ? 568 - 400 : 50);
    };



    useEffect(() => {


        const handleResize = () => {
            updateSticky();
        };

        const handleScroll = () => {
            updateSticky();
        };

        // Add event listeners
        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        // Initial updates
        handleResize();
        updateSticky();

        return () => {
            // Clean up event listeners
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };

    }, [showNavbar]);


    const LeftContent = () => (
            <div>
                <Grid item xs={12} md={11}>
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 600, // Adjust font weight as needed
                        textAlign: 'left',
                        marginLeft: 3,
                        fontSize: { xs: 24, sm: 30, md: 40 },
                    }}>
                        Dreaming of owning your own business?
                    </Typography>
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 500, // Adjust font weight as needed
                        textAlign: 'left',
                        marginLeft: 3,
                        fontSize: { xs: 18, sm: 20, md: 24 }
                    }}>
                        Acquiring an established company is a smart way to hit the ground running. With our SBA 7(a) loans, you can finance your first business purchase with confidence, enjoying flexible terms and competitive rates. Our loans offer all the standard benefits of SBA 7(a) financing, including:
                    </Typography>
                </Grid>
                <br />
                <Grid item xs={12} md={11} sx={{ marginLeft: { xs: 4, sm: 6, md: 10 } }} >
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 600, // Adjust font weight as needed
                        textAlign: 'left',
                        fontSize: { xs: 18, sm: 20, md: 20 }
                    }}>
                        Long-Term Loans:
                    </Typography>
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 500, // Adjust font weight as needed
                        textAlign: 'left',
                        fontSize: { xs: 17, sm: 20, md: 20 }
                    }}>
                        <ul style={{ marginTop: '0.2rem' }}>
                            <li>Up to 25 years if your purchase includes commercial real estate.</li>
                            <li>Up to 10 years without commercial real estate.</li>
                        </ul>

                    </Typography>
                </Grid>
                <Grid item xs={12} md={11} sx={{ marginLeft: { xs: 4, sm: 6, md: 10 } }} >
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 600, // Adjust font weight as needed
                        textAlign: 'left',
                        fontSize: { xs: 18, sm: 20, md: 20 }
                    }}>
                        Competitive Interest Rates:
                    </Typography>
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 500, // Adjust font weight as needed
                        textAlign: 'left',
                        fontSize: { xs: 17, sm: 20, md: 20 }
                    }}>
                        <ul style={{ marginTop: '0.2rem' }}>
                            <li>Receive a clear, competitive, written interest rate estimate in less than 5 minutes with our easy <a href="../prequalify/standard">prequalification</a>. </li>
                        </ul>

                    </Typography>
                </Grid>
                <Grid item xs={12} md={11} sx={{ marginLeft: { xs: 4, sm: 6, md: 10 } }} >
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 600, // Adjust font weight as needed
                        textAlign: 'left',
                        fontSize: { xs: 18, sm: 20, md: 20 },
                        marginTop: '30px'
                    }}>
                        Lower Equity/Down Payment:
                    </Typography>
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 500, // Adjust font weight as needed
                        textAlign: 'left',
                        fontSize: { xs: 17, sm: 20, md: 20 }
                    }}>
                        <ul style={{ marginTop: '0.2rem' }}>
                            <li>Typically, a 10% equity down payment is required.</li>
                            <li>If you're expanding your existing business in the same industry, you may qualify for a 0% down payment. (See our  <a
                                href="https://thenewsouthbank.com/loanuses/expansion"  // Replace with your actual link
                                style={{
                                    color: theme.palette.primary.main, // Example using theme color
                                    textDecoration: 'underline',
                                }}
                            >
                                Business Expansion
                            </a>  page for more information.)</li>
                        </ul>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={11} sx={{ marginLeft: { xs: 4, sm: 6, md: 10 } }}>
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 600, // Adjust font weight as needed
                        textAlign: 'left',
                        fontSize: { xs: 18, sm: 20, md: 20 },
                        marginTop: '30px'
                    }}>
                        Ability to Use Seller Financing:
                    </Typography>
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 500, // Adjust font weight as needed
                        textAlign: 'left',
                        fontSize: { xs: 17, sm: 20, md: 20 }
                    }}>
                        <ul style={{ marginTop: '0.2rem' }}>
                            <li>Up to 75% of the equity required can be in the form of seller financing.</li>
                            <li>Seller financing will be required to be on full or partial standby for the first 24 months and may not include a balloon. </li>
                        </ul>

                    </Typography>
                </Grid>
                <br />
                <PrequalifyCard />
                <br/>
                <br/>
                <Grid item xs={12} md={11}>
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 600, // Adjust font weight as needed
                        textAlign: 'left',
                        marginLeft: { xs: 2, sm: 2, md: 4 },
                        fontSize: { xs: 24, sm: 30, md: 40 }
                    }}>
                        Knowledge is Power
                    </Typography>
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 500, // Adjust font weight as needed
                        textAlign: 'left',
                        marginLeft: { xs: 2, sm: 2, md: 4 },
                        fontSize: { xs: 18, sm: 20, md: 24 }
                    }}>
                        Navigating a business acquisition can be complex, but understanding key details can simplify the process. When applying for an SBA 7(a) loan to finance your purchase, consider the following essentials:
                    </Typography>
                </Grid>
                <br />
                <Grid item xs={12} md={11} sx={{ marginLeft: { xs: 4, sm: 6, md: 10 } }} >
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 500, // Adjust font weight as needed
                        textAlign: 'left',
                        fontSize: { xs: 17, sm: 20, md: 20 }
                    }}>
                        <span><b>Purchase Agreement:</b> You'll need to submit a copy of the purchase agreement with your loan application.</span>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={11} sx={{ marginLeft: { xs: 4, sm: 6, md: 10 } }} >
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 500, // Adjust font weight as needed
                        textAlign: 'left',
                        fontSize: { xs: 17, sm: 20, md: 20 },
                        marginTop: '30px'
                    }}>
                        <span><b>Business Valuation:</b> A professional business valuation is required to determine the fair market value of the business you're buying.</span>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={11} sx={{ marginLeft: { xs: 4, sm: 6, md: 10 } }} >
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 500, // Adjust font weight as needed
                        textAlign: 'left',
                        fontSize: { xs: 17, sm: 20, md: 20 },
                        marginTop: '30px'
                    }}>
                        <span><b>Loan Limits:</b> The maximum SBA 7(a) loan amount for a business acquisition is capped at the business's valuation.</span>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={11} sx={{ marginLeft: { xs: 4, sm: 6, md: 10 } }} >
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 500, // Adjust font weight as needed
                        textAlign: 'left',
                        fontSize: { xs: 17, sm: 20, md: 20 },
                        marginTop: '30px'
                    }}>
                        <span><b>Seller Documentation:</b> We require the seller's tax returns and interim financial statements to assess the business's financial health.</span>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={11} sx={{ marginLeft: { xs: 4, sm: 6, md: 10 } }} >
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 500, // Adjust font weight as needed
                        textAlign: 'left',
                        fontSize: { xs: 17, sm: 20, md: 20 },
                        marginTop: '30px'
                    }}>
                        <span><b>No Seller Earnouts:</b> Seller earnouts, where payments depend on future performance, are prohibited under our SBA 7(a) loans.</span>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={11} sx={{ marginLeft: { xs: 4, sm: 6, md: 10 } }} >
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 500, // Adjust font weight as needed
                        textAlign: 'left',
                        fontSize: { xs: 17, sm: 20, md: 20 },
                        marginTop: '30px'
                    }}>
                        <span><b>Buyer Rebates:</b> Rebates based on future business performance are allowed.</span>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={11} sx={{ marginLeft: { xs: 4, sm: 6, md: 10 } }} >
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 500, // Adjust font weight as needed
                        textAlign: 'left',
                        fontSize: { xs: 17, sm: 20, md: 20 },
                        marginTop: '30px'
                    }}>
                        <span><b>Site Visit:</b> A site visit will be conducted before closing to ensure everything is in order.</span>
                    </Typography>
                </Grid>
            </div>

        )




    return (
        <div style={{ marginTop: '0rem' }}>
            <div id="image-container" style={{
                position: 'relative',
                marginTop: '40px',
                width: '100%'
            }}>
                <img
                    src={businessacquisitionfull}
                    alt='Business Acquisition'
                    style={{
                        width: '100%',
                        maxHeight: '400px',
                        minHeight: '250px',
                        objectFit: 'cover',
                        objectPosition: 'center 65%',
                        display: 'block',  // Removes any extra space below the image
                    }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: { xs: '60px', sm: '80px', md: '100px' },
                        backgroundColor: alpha(theme.palette.primary.dark, 0.8),
                        zIndex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start', // Use 'flex-start' instead of 'left'
                    }}
                >
                    <Typography
                        component="span"
                        sx={{
                            color: 'white',
                            fontWeight: 600, // Adjust font weight as needed
                            textAlign: 'left',
                            marginLeft: 3,
                            fontSize: { xs: 24, sm: 34, md: 50 },
                            lineHeight: 1.1
                        }}
                    >
                        BUSINESS ACQUISITION LOANS
                    </Typography>
                </Box>
            </div>
            <StickySide isSticky={isSticky} stickyHeight={stickyHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar}
                leftContent={<LeftContent />}
                leftSize='7'
                otherLoanUses={true} />

            <br />
            <br />
            <br />
            <br />
        </div >
    );
}




