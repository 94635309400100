import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import ToggleCard from './ToggleCard'
import { alpha } from '@mui/material/styles';
import { Typography, Grid, Box } from '@mui/material';
import StickySide from './StickySide';
import workingcapital2 from '../images/workingcapital8.png'
import workingcapital from '../images/workingCapitalBanner2.png'


export default function WorkingCapital({ navBarHeight, footerHeight, open, setOpen, showNavbar }) {
    const theme = useTheme();


    const [isSticky, setIsSticky] = useState(false);
    const [stickyHeight, setStickyHeight] = useState(0);


    const updateSticky = () => {
        const offset = document.getElementById('image-container').offsetHeight;
        setIsSticky(window.scrollY > offset);
        //setStickyHeight(menuPosition - offset)
        setStickyHeight(showNavbar ? 568 - 400 : 50);
    };



    useEffect(() => {


        const handleResize = () => {
            updateSticky();
        };

        const handleScroll = () => {
            updateSticky();
        };

        // Add event listeners
        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        // Initial updates
        handleResize();
        updateSticky();

        return () => {
            // Clean up event listeners
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };

    }, [showNavbar]);


    const LeftContent = () => (
        <div>
            <Grid item xs={12} sm={12} md={8} sx={{ marginRight: { xs: 4, sm: 2, md: 0 }, marginLeft: { xs: 2, sm: 2, md: 4 } }}>
                <Grid item xs={12} md={10} sx={{ marginRight: { xs: 4, sm: 2, md: 0 } }}>
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 600, // Adjust font weight as needed
                        textAlign: 'left',
                        marginLeft: 3,
                        fontSize: { xs: 24, sm: 26, md: 30 },
                        marginBottom: 2
                    }}>
                        Need funds to manage your day-to-day operations?
                    </Typography>
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 500, // Adjust font weight as needed
                        textAlign: 'left',
                        marginLeft: 3,
                        fontSize: { xs: 16, sm: 18, md: 20 },
                        marginBottom: 2
                    }}>
                        Our SBA 7(a) Working Capital Loans are designed to provide the flexibility and financial support your business needs to thrive.
                    </Typography>
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 500, // Adjust font weight as needed
                        textAlign: 'left',
                        marginLeft: 3,
                        fontSize: { xs: 16, sm: 18, md: 20 },
                        marginBottom: 2
                    }}>
                        Whether you're covering short-term expenses, preparing for seasonal demands, or managing cash flow, our working capital options are a smart solution to keep your business running smoothly.
                    </Typography>
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 500, // Adjust font weight as needed
                        textAlign: 'left',
                        marginLeft: 3,
                        fontSize: { xs: 16, sm: 18, md: 20 },
                        marginBottom: 2
                    }}>
                        With competitive rates, flexible terms, and the ability to combine working capital with other loan purposes like equipment purchases, real estate acquisition, or business expansion, you get a complete financial solution tailored to your business's specific needs.
                    </Typography>
                </Grid>
                <br />
                <br />
                <Grid item xs={12} md={10}>
                    <ToggleCard />
                </Grid>
            </Grid>
        </div>

    )




    return (
        <div style={{ marginTop: '0rem' }}>
            <div id="image-container" style={{
                position: 'relative',
                marginTop: '40px',
                width: '100%'
            }}>
                <img
                    src={workingcapital}
                    alt='Working Capital'
                    style={{
                        width: '100%',
                        maxHeight: '400px',
                        minHeight: '250px',
                        objectFit: 'cover',
                        objectPosition: 'center 30%',
                        display: 'block',  // Removes any extra space below the image
                    }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: { xs: '60px', sm: '80px', md: '100px' },
                        backgroundColor: alpha(theme.palette.primary.dark, 0.8),
                        zIndex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start', // Use 'flex-start' instead of 'left'
                    }}
                >
                    <Typography
                        component="span"
                        sx={{
                            color: 'white',
                            fontWeight: 600, // Adjust font weight as needed
                            textAlign: 'left',
                            marginLeft: 3,
                            fontSize: { xs: 24, sm: 34, md: 50 },
                            lineHeight: 1.1
                        }}
                    >
                        WORKING CAPITAL LOANS
                    </Typography>
                </Box>
            </div>
            <StickySide isSticky={isSticky} stickyHeight={stickyHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar}
                leftContent={<LeftContent />}
                leftSize=''
                otherLoanUses={true} />

            <br />
            <br />
            <br />
            <br />
        </div >
    );
}




