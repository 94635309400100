import * as React from 'react';
import Card from '@mui/material/Card';
import { Link, Stack } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

export default function LearnMoreMediaCard({ width, image, imagename, text, keyName, descr, linkClick, buttonText, icon }) {
    const theme = useTheme();

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }} >
            <a href={linkClick} style={{ textDecoration: 'none' }}>
            <Card sx={{
                display: 'flex', width: width, height: 50,
                alignItems: 'center',
                backgroundColor: 'white',
                transition: 'background-color 0.3s',
                border: `1px solid ${theme.palette.primary.bright}`,
                '&:hover': {
                    backgroundColor: theme.palette.secondary.contrastText,
                    boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.2)',
                    opacity: '0.8',
                    cursor: 'pointer',
                },
            }}>
                <CardContent sx={{
                    display: 'flex',          // Use flexbox
                    flexDirection: 'column',  // Align children in a column
                    justifyContent: 'center', // Center items vertically
                    alignItems: 'flex-start',     // align left
                    height: '100%',
                    width: '100%',
                    padding: 1,
                    paddingBottom: 0,
                }} >
                    <Stack
                        direction="row"
                        justifyContent="flext-start"
                        alignItems="center"
                        spacing={2}
                        marginBottom='-16px'
                        
                    >
                    {React.cloneElement(icon, { sx: { color: theme => theme.palette.primary.red } })}
                    <Typography variant="h4" fontWeight={550}
                        sx={{
                            fontSize: {
                                xs: '20px', // Adjust font size for extra-small screens (phones)
                                sm: '20px', // Adjust font size for small screens (tablets)
                                md: '17px', // Adjust font size for medium screens (small desktops)
                                lg: '20px', // Adjust font size for large screens (desktops)
                                xl: '20px', // Adjust font size for extra-large screens (large desktops and above)
                            },
                            color: theme.palette.primary.dark, textAlign: 'left'
                        }}>
                        {text}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: {
                                xs: '14px', // Adjust font size for extra-small screens (phones)
                                sm: '14px', // Adjust font size for small screens (tablets)
                                md: '14px', // Adjust font size for medium screens (small desktops)
                                lg: '14px', // Adjust font size for large screens (desktops)
                                xl: '14px', // Adjust font size for extra-large screens (large desktops and above)
                            },
                            color: 'secondary.light', textAlign: 'left', 
                        }}>
                        {descr}
                    </Typography>
                    </Stack>
                </CardContent>
            </Card>
            </a>
        </div>
    );
}
