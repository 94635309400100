import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import expansionbanner from '../images/expansionbanner2.png'
import { alpha } from '@mui/material/styles';
import { Typography, Grid, Box, Stack, useMediaQuery } from '@mui/material';
import StickySide from './StickySide';
import PrequalifyCard from './PrequalifyCard';
import StoreIcon from '@mui/icons-material/Store';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import GroupsIcon from '@mui/icons-material/Groups';
import HandshakeIcon from '@mui/icons-material/Handshake';
import equipmentBanner1 from '../images/equipmentBanner1.png'
import equipmentBanner2 from '../images/equipmentBanner2.png'
import equipmentBanner3 from '../images/equipmentBanner3.png'
import equipmentBanner4 from '../images/equipmentBanner4.png'
import equipmentBanner5 from '../images/equipmentBanner5.png'
import equipmentBanner6 from '../images/equipmentBanner6.png'
import MediaCardNoClick from './MediaCardNoClick'
import startimage from '../images/equipmentSub4.png'
import growingimage from '../images/equipmentSub2.png'
import retiredimage from '../images/equipmentSub3.png'

export default function Equipment({ navBarHeight, footerHeight, open, setOpen, showNavbar }) {
    const theme = useTheme();


    const [isSticky, setIsSticky] = useState(false);
    const [stickyHeight, setStickyHeight] = useState(0);


    const updateSticky = () => {
        const offset = document.getElementById('image-container').offsetHeight;
        setIsSticky(window.scrollY > offset);
        //setStickyHeight(menuPosition - offset)
        setStickyHeight(showNavbar ? 568 - 400 : 50);
    };



    useEffect(() => {


        const handleResize = () => {
            updateSticky();
        };

        const handleScroll = () => {
            updateSticky();
        };

        // Add event listeners
        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        // Initial updates
        handleResize();
        updateSticky();

        return () => {
            // Clean up event listeners
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };

    }, [showNavbar]);

    const halfBackgroundStyle = {
        background: `linear-gradient(
    to top,
    transparent 0%, 
    transparent 10%, 
    ${theme.palette.primary.contrastText} 10%, 
    ${theme.palette.primary.contrastText} 25%, 
    transparent 25%, 
    transparent 100%
  )`,
        display: 'inline',
    };


    const LeftContent = () => (
        <div>
            <Grid item xs={12} md={11}>
            <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 600, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 24, sm: 28, md: 30 },
                }}>
                    Need New Equipment? Let's Cover All Your Bases.
                </Typography>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 24 }
                }}>
                    Investing in equipment is crucial, but it’s rarely the only financial need on your plate. As a business owner, you might also be looking to secure working capital for daily operations or refinance existing debt to free up resources. Our SBA 7(a) loans are designed to meet these combined needs, offering you a flexible, <span style={halfBackgroundStyle}>all-in-one financial solution</span>.
                </Typography>
            </Grid>
            <br />
            <Grid item xs={10} md={11} sx={{ marginLeft: { xs: 0, sm: 0, md: 0 } }} >
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 600, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 24, sm: 28, md: 28 },
                }}>
                    Finance More Than Just Equipment
                </Typography>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 20 }
                }}>
                    When you choose an SBA 7(a) loan, you’re not just financing equipment—you’re optimizing your entire business strategy. Here’s how:
                </Typography>
                {/* <br />
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 20 }
                }}>
                    <b>Upgrade Your Equipment While Keeping Cash Flow Smooth:</b> Balancing the need for new equipment with the realities of day-to-day expenses can be challenging. With an SBA 7(a) loan, you can finance your equipment purchase and also secure the working capital you need to keep operations running smoothly. Imagine upgrading your manufacturing line while still covering payroll and inventory costs without a hitch.
                </Typography>
                <br />
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 20 }
                }}>
                    <b>Simplify Your Financial Commitments:</b> If you’re juggling multiple loans or managing high-interest debt, adding another loan for equipment might feel overwhelming. An SBA 7(a) loan allows you to consolidate your debt and finance new equipment in one streamlined payment. This means you can upgrade your commercial fleet and reduce your monthly financial burden, making cash flow more predictable and manageable.
                </Typography>
                <br />
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 20 }
                }}>
                    <b>Plan for Growth with Confidence:</b> Planning to expand your business? New equipment might be just one part of the equation. With an SBA 7(a) loan, you can not only finance the tools you need but also secure additional funds to support your growth initiatives, whether that’s opening a new location, hiring more staff, or boosting your marketing efforts.
                </Typography> */}


            </Grid>

            <Grid item xs={11} md={10} sx={{ marginLeft: { xs: 2, sm: 2, md: 4 }, marginTop: 6 }} >
                <MediaCardNoClick 
                    image={startimage} 
                    imagename='Upgrade Equipment'
                    title={<span>Upgrade Equipment<br/>Keep Cash Flow Smooth</span>}
                    /* descr='Balancing the need for new equipment with the realities of day-to-day expenses can be challenging. With an SBA 7(a) loan, you can finance your equipment purchase and also secure the working capital you need to keep operations running smoothly. Imagine upgrading your manufacturing line while still covering payroll and inventory costs without a hitch.' */
                    descr='Investing in new equipment without straining daily expenses is a challenge. With an SBA 7(a) loan, you can purchase the equipment you need while maintaining essential working capital for things like payroll and inventory.'
                    isMediaLeft={true}
                    imagePosition='center 50%'
                    height={{ xs: '380px', sm: '220px', md: '260px', lg: '260px'}}
                    />
            </Grid>
            <Grid item xs={11} md={10} sx={{ marginLeft: { xs: 2, sm: 2, md: 4 }, marginTop: 6 }} >
                <MediaCardNoClick 
                    image={growingimage} 
                    imagename='Simplify Finances'
                    title='Simplify Finances' 
                    descr='If you’re juggling multiple loans or managing high-interest debt, adding another loan for equipment might feel overwhelming. An SBA 7(a) loan allows you to consolidate your debt and finance new equipment in one streamlined payment. This means you can upgrade your commercial fleet and reduce your monthly financial burden, making cash flow more predictable and manageable.'
                    isMediaLeft={false}
                    imagePosition='center top'
                    height={{ xs: '380px', sm: '220px', md: '260px', lg: '260px'}}
                    />
            </Grid>
            <Grid item xs={11} md={10} sx={{ marginLeft: { xs: 2, sm: 2, md: 4 }, marginTop: 6 }} >
                <MediaCardNoClick 
                    image={retiredimage} 
                    imagename='Plan for Growth'
                    title='Plan for Growth' 
                    descr='Planning to expand your business? New equipment might be just one part of the equation. With an SBA 7(a) loan, you can not only finance the tools you need but also secure additional funds to support your growth initiatives, whether that’s opening a new location, hiring more staff, or boosting your marketing efforts.'
                    isMediaLeft={true}
                    imagePosition='center 20%'
                    height={{ xs: '380px', sm: '220px', md: '260px', lg: '260px'}}
                    />
            </Grid>
            <br />
            <br />
            <PrequalifyCard />
            <br />
            <br />
            <Grid item xs={12} md={10}>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 600, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: { xs: 2, sm: 2, md: 4 },
                    fontSize: { xs: 24, sm: 28, md: 30 },
                    marginBottom: 2
                }}>
                    Why SBA 7(a) Loans Are Ideal for Multi-Purpose Financing
                </Typography>
                <br/>
                {/* <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 500, // Adjust font weight as needed
                        textAlign: 'left',
                        marginLeft: { xs: 2, sm: 2, md: 4 },
                        fontSize: { xs: 18, sm: 20, md: 20 }
                    }}>
                        <ul>
                            <li><b>Real Estate Acquisition:</b> Purchase a larger facility, buy your current building or open a new location.</li>
                            <li><b>Equipment & Inventory:</b> Fund essential purchases without straining cash flow.</li>
                            <li><b>Hiring:</b> Secure capital to onboard and train new staff.</li>
                            <li><b>Mergers & Acquisitions:</b> Facilitate strategic growth through acquisitions.</li>
                        </ul>
                    </Typography> */}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6} >
                        <Stack
                            direction="column"
                            spacing={0}
                            sx={{
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                marginBottom: 2
                            }}
                        >
                            {/* <StoreIcon sx={{
                                color: theme.palette.primary.bright, fontSize: 50,
                                marginLeft: { xs: 2, sm: 2, md: 4 }
                            }} /> */}
                            <Typography sx={{
                                color: theme.palette.primary.dark,
                                fontWeight: 500, // Adjust font weight as needed
                                textAlign: 'left',
                                marginLeft: { xs: 2, sm: 2, md: 4 },
                                fontSize: { xs: 18, sm: 20, md: 20 }
                            }}>
                                <b>One Loan, Multiple Benefits</b>
                            </Typography>
                            <Typography sx={{
                                color: theme.palette.secondary.light,
                                fontWeight: 500, // Adjust font weight as needed
                                textAlign: 'left',
                                marginLeft: { xs: 2, sm: 2, md: 4 },
                                fontSize: { xs: 18, sm: 20, md: 20 }
                            }}>
                                Combine equipment financing, working capital, and debt refinancing into a single, easy-to-manage loan.
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} >  
                        <Stack
                            direction="column"
                            spacing={0}
                            sx={{
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                marginBottom: 2
                            }}
                        >
                            {/* <AgricultureIcon sx={{
                                color: theme.palette.primary.bright, fontSize: 50,
                                marginLeft: { xs: 2, sm: 2, md: 4 }
                            }} /> */}
                            <Typography sx={{
                                color: theme.palette.primary.dark,
                                fontWeight: 500, // Adjust font weight as needed
                                textAlign: 'left',
                                marginLeft: { xs: 2, sm: 2, md: 4 },
                                fontSize: { xs: 18, sm: 20, md: 20 }
                            }}>
                                <b>Competitive Rates</b>
                            </Typography>
                            <Typography sx={{
                                color: theme.palette.secondary.light,
                                fontWeight: 500, // Adjust font weight as needed
                                textAlign: 'left',
                                marginLeft: { xs: 2, sm: 2, md: 4 },
                                fontSize: { xs: 18, sm: 20, md: 20 }
                            }}>
                                Take advantage of SBA-backed rates that are typically lower than those of separate loans from different lenders.
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} >  
                        <Stack
                            direction="column"
                            spacing={0}
                            sx={{
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                marginBottom: 2
                            }}
                        >
                            {/* <GroupsIcon sx={{
                                color: theme.palette.primary.bright, fontSize: 50,
                                marginLeft: { xs: 2, sm: 2, md: 4 }
                            }} /> */}
                            <Typography sx={{
                                color: theme.palette.primary.dark,
                                fontWeight: 500, // Adjust font weight as needed
                                textAlign: 'left',
                                marginLeft: { xs: 2, sm: 2, md: 4 },
                                fontSize: { xs: 18, sm: 20, md: 20 }
                            }}>
                                <b>Long-Term Flexibility</b>
                            </Typography>
                            <Typography sx={{
                                color: theme.palette.secondary.light,
                                fontWeight: 500, // Adjust font weight as needed
                                textAlign: 'left',
                                marginLeft: { xs: 2, sm: 2, md: 4 },
                                fontSize: { xs: 18, sm: 20, md: 20 }
                            }}>
                                With repayment terms up to 15 years, you can plan your finances with greater certainty and focus on growth.
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} >  
                        <Stack
                            direction="column"
                            spacing={0}
                            sx={{
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                marginBottom: 2
                            }}
                        >
                            {/* <HandshakeIcon sx={{
                                color: theme.palette.primary.bright, fontSize: 50,
                                marginLeft: { xs: 2, sm: 2, md: 4 }
                            }} /> */}
                            <Typography sx={{
                                color: theme.palette.primary.dark,
                                fontWeight: 500, // Adjust font weight as needed
                                textAlign: 'left',
                                marginLeft: { xs: 2, sm: 2, md: 4 },
                                fontSize: { xs: 18, sm: 20, md: 20 }
                            }}>
                                <b>Tailored to Your Needs</b>
                            </Typography>
                            <Typography sx={{
                                color: theme.palette.secondary.light,
                                fontWeight: 500, // Adjust font weight as needed
                                textAlign: 'left',
                                marginLeft: { xs: 2, sm: 2, md: 4 },
                                fontSize: { xs: 18, sm: 20, md: 20 }
                            }}>
                                Whether you need $50,000 or $5 million, our loans can be customized to fit your specific business goals.
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>


        </div>

    )

    const ImageGrid = () => {
        // Use MUI's breakpoints to hide/show images at different screen sizes
        const isXs = useMediaQuery(theme.breakpoints.down('xs'));
        const isSm = useMediaQuery(theme.breakpoints.down('sm'));
        const isMd = useMediaQuery(theme.breakpoints.down('md'));
        const isLg = useMediaQuery(theme.breakpoints.down('lg'));

        // Logic to control how many images are displayed based on screen size
        let numImagesToShow = 5;
        if (isLg) numImagesToShow = 5;
        if (isMd) numImagesToShow = 3;
        if (isSm) numImagesToShow = 3;
        if (isXs) numImagesToShow = 2;

        const images = [
            equipmentBanner1,
            equipmentBanner2,
            equipmentBanner3,
            equipmentBanner5,
            equipmentBanner6,
            equipmentBanner4,
        ];

        const imageMarginTop = isXs ? '-20px' :
                           isSm ? '-20px' :
                           isMd ? '-20px' :
                           '0px';
    return (
        <Box id="image-container" sx={{
            position: 'relative',
            marginTop: '40px',
            width: '100%',
            height: {xs: '250px', sm: '300px', md: '400px', lg: '400px', xl: '400px'},
            overflow: 'hidden',
        }}>
                <Grid container spacing={{ xs: 1, sm: 0.5, md: 1, lg: 0.5}} paddingTop={7}>
                    {images.slice(0, numImagesToShow).map((image, index) => (
                        <Grid item xs={4} sm={4} md={3} lg={2.4} key={index}>
                            <img
                                src={image}
                                alt={`Person ${index + 1}`}
                                style={{
                                    width: '100%',
                                    maxHeight: '400px',
                                    minHeight: '250px',
                                    objectFit: 'cover',
                                    objectPosition: 'center 40%',
                                    display: 'block',  // Removes any extra space below the image
                                    marginTop: imageMarginTop 

                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: { xs: '60px', sm: '80px', md: '100px' },
                        backgroundColor: alpha(theme.palette.primary.dark, 0.8),
                        zIndex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                    }}
                >
                    <Typography
                        component="span"
                        sx={{
                            color: 'white',
                            fontWeight: 600,
                            textAlign: 'left',
                            marginLeft: 3,
                            fontSize: { xs: 24, sm: 34, md: 50 },
                            lineHeight: 1.1,
                        }}
                    >
                        EQUIPMENT & MACHINERY LOANS
                    </Typography>
                </Box>
            </Box>

        );
    };


    return (
        <div style={{ marginTop: '0rem' }}>
            <ImageGrid />
            <StickySide isSticky={isSticky} stickyHeight={stickyHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar}
                leftContent={<LeftContent />}
                leftSize='7'
                otherLoanUses={true} />

            <br />
            <br />
            <br />
            <br />
        </div >
    );
}




