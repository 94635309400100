import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import ToggleCard from './ToggleCard'
import { alpha } from '@mui/material/styles';
import { Typography, Grid, Box, useMediaQuery } from '@mui/material';
import StickySide from './StickySide';
import industry1 from '../images/industry6.png'
import industry2 from '../images/industry7.png'
import industry3 from '../images/industry3.png'
import industry4 from '../images/industry4.png'
import industry5 from '../images/industry8.png'
import industry6 from '../images/industry6.png'


export default function WorkingCapital({ navBarHeight, footerHeight, open, setOpen, showNavbar }) {
    const theme = useTheme();


    const [isSticky, setIsSticky] = useState(false);
    const [stickyHeight, setStickyHeight] = useState(0);


    const updateSticky = () => {
        const offset = document.getElementById('image-container').offsetHeight;
        setIsSticky(window.scrollY > offset);
        //setStickyHeight(menuPosition - offset)
        setStickyHeight(showNavbar ? 568 - 400 : 50);
    };



    useEffect(() => {


        const handleResize = () => {
            updateSticky();
        };

        const handleScroll = () => {
            updateSticky();
        };

        // Add event listeners
        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        // Initial updates
        handleResize();
        updateSticky();

        return () => {
            // Clean up event listeners
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };

    }, [showNavbar]);


    const ImageGrid = () => {
        // Use MUI's breakpoints to hide/show images at different screen sizes
        const isXs = useMediaQuery(theme.breakpoints.down('xs'));
        const isSm = useMediaQuery(theme.breakpoints.down('sm'));
        const isMd = useMediaQuery(theme.breakpoints.down('md'));
        const isLg = useMediaQuery(theme.breakpoints.down('lg'));

        // Logic to control how many images are displayed based on screen size
        let numImagesToShow = 5;
        if (isLg) numImagesToShow = 5;
        if (isMd) numImagesToShow = 3;
        if (isSm) numImagesToShow = 3;
        if (isXs) numImagesToShow = 2;

        const images = [
            industry1,
            industry2,
            industry3,
            industry4,
            industry5,
            industry6,
        ];

        const imageMarginTop = isXs ? '-20px' :
                           isSm ? '-20px' :
                           isMd ? '-20px' :
                           '0px';
    return (
        <Box id="image-container" sx={{
            position: 'relative',
            marginTop: '40px',
            width: '100%',
            height: {xs: '250px', sm: '300px', md: '400px', lg: '400px', xl: '400px'},
            overflow: 'hidden',
        }}>
                <Grid container spacing={{ xs: 1, sm: 0.5, md: 1, lg: 0.5}} paddingTop={7}>
                    {images.slice(0, numImagesToShow).map((image, index) => (
                        <Grid item xs={4} sm={4} md={3} lg={2.4} key={index}>
                            <img
                                src={image}
                                alt={`Person ${index + 1}`}
                                style={{
                                    width: '100%',
                                    maxHeight: '400px',
                                    minHeight: '250px',
                                    objectFit: 'cover',
                                    objectPosition: 'center 40%',
                                    display: 'block',  // Removes any extra space below the image
                                    marginTop: imageMarginTop 

                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: { xs: '60px', sm: '80px', md: '100px' },
                        backgroundColor: alpha(theme.palette.primary.dark, 0.8),
                        zIndex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                    }}
                >
                    <Typography
                        component="span"
                        sx={{
                            color: 'white',
                            fontWeight: 600,
                            textAlign: 'left',
                            marginLeft: 3,
                            fontSize: { xs: 24, sm: 34, md: 50 },
                            lineHeight: 1.1,
                        }}
                    >
                        INDUSTRY RESOURCES
                    </Typography>
                </Box>
            </Box>

        );
    };


    const LeftContent = () => (
        <div>
            <Grid item xs={12} sm={12} md={8} sx={{ marginRight: { xs: 4, sm: 2, md: 0 }, marginLeft: { xs: 2, sm: 2, md: 4 } }}>
                <Grid item xs={12} md={10} sx={{ marginRight: { xs: 4, sm: 2, md: 0 } }}>
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 600, // Adjust font weight as needed
                        textAlign: 'left',
                        marginLeft: 3,
                        fontSize: { xs: 24, sm: 26, md: 30 },
                        marginBottom: 2
                    }}>
                        SBA 7(a) Financing for Your Industry
                    </Typography>
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 500, // Adjust font weight as needed
                        textAlign: 'left',
                        marginLeft: 3,
                        fontSize: { xs: 16, sm: 18, md: 20 },
                        marginBottom: 2
                    }}>
                        Unlock your business’s potential with SBA loans tailored for your industry. Get started today and see how our industry-specific solutions can help you achieve your business goals.
                    </Typography>
                </Grid>
                <Grid item xs={4} md={2} sx={{ marginLeft: { xs: 4, sm: 6, md: 10 } }} >
                    <a
                        href="./industry/veterinary"
                        style={{
                            textDecoration: 'none', // Remove default underline
                            color: theme.palette.secondary.light, // Color for the link
                            margin: '20px', // Margin around the link
                            cursor: 'pointer', // Pointer cursor on hover
                            transition: 'text-decoration 0.3s ease', // Smooth transition for underline
                        }}
                        onMouseOver={(e) => e.currentTarget.style.textDecoration = 'underline'}
                        onMouseOut={(e) => e.currentTarget.style.textDecoration = 'none'}
                    >
                        <Typography
                            fontSize={20}
                            fontWeight={550}
                            sx={{ textAlign: 'left' }}
                        >
                            <span style={{ color: theme.palette.primary.main }}>
                                Veterinary
                            </span>
                        </Typography>
                    </a>
                </Grid>
                <Grid item xs={4} md={2} sx={{ marginLeft: { xs: 4, sm: 6, md: 10 } }} >
                    <a
                        href="http://localhost:3000/industry/childcare"
                        style={{
                            textDecoration: 'none', // Remove default underline
                            color: theme.palette.secondary.light, // Color for the link
                            margin: '20px', // Margin around the link
                            cursor: 'pointer', // Pointer cursor on hover
                            transition: 'text-decoration 0.3s ease', // Smooth transition for underline
                        }}
                        onMouseOver={(e) => e.currentTarget.style.textDecoration = 'underline'}
                        onMouseOut={(e) => e.currentTarget.style.textDecoration = 'none'}
                    >
                        <Typography
                            fontSize={20}
                            fontWeight={550}
                            sx={{ textAlign: 'left' }}
                        >
                            <span style={{ color: theme.palette.primary.main }}>
                                Child Care
                            </span>
                        </Typography>
                    </a>

                </Grid>
            </Grid>
        </div>

    )




    return (
        <div style={{ marginTop: '0rem' }}>
            <ImageGrid />
            <StickySide isSticky={isSticky} stickyHeight={stickyHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar}
                leftContent={<LeftContent />}
                leftSize='7'
                otherLoanUses={true} />

            <br />
            <br />
            <br />
            <br />
        </div >
    );
}




