import React, { useState, useEffect, useRef } from 'react';
import { useScroll, useTransform, motion, useMotionValueEvent } from 'framer-motion'
import { useTheme } from '@mui/material/styles';
import businessacquisitionfull from '../images/businessacquisitionfull.png'
import { alpha } from '@mui/material/styles';
import { Typography, Grid, useMediaQuery, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LearnMoreMediaCard from './LearnMoreMediaCard';
import LearnMoreButton from './LearnMoreButton'
import LearnMoreButtonDropDown from './LearnMoreButtonDropDown'
import HelpIcon from '@mui/icons-material/Help';
import SchoolIcon from '@mui/icons-material/School'
import AddTaskIcon from '@mui/icons-material/AddTask';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';

export default function StickySide({ navBarHeight, footerHeight, open, setOpen, isSticky, stickyHeight,
    showNavbar, leftContent, leftSize, otherLoanUses
}) {
    const theme = useTheme();
    const isXSmallScreen = useMediaQuery('(max-width:600px)');
    const isSmallScreen = useMediaQuery('(max-width:900px)');
    const isMediumScreen = useMediaQuery('(max-width:1200px)');

    const menuRef = useRef(null)

    const [fullScrollHeight, setFullScrollHeight] = useState(document.documentElement.scrollHeight);
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
    const [scrollRate, setScrollRate] = useState(0);
    const [framer2, setFramer2] = useState(0);
    const [scrollPercent, setScrollPercent] = useState(0)
    const leftSideRef = useRef(null);
    const [leftSideHeight, setLeftSideHeight] = useState(0)
    const [isBottomFixed, setIsBottomFixed] = useState(false)
    const [menuHeight, setMenuHeight] = useState(0)


    const updateLeftSideHeight = () => {
        if (leftSideRef.current) {
            const rect = leftSideRef.current.getBoundingClientRect();
            setLeftSideHeight(rect.height); // Set the full height
        }
    };

    const updateMenuHeight = () => {
        if (menuRef.current) {
            const rect = menuRef.current.getBoundingClientRect();
            setMenuHeight(rect.height); // Set the full height
        }
    };

    const handleBottomFixed = () => {
        if (window.scrollY >= (leftSideHeight + window.innerHeight - menuHeight) && !open) {
            setIsBottomFixed(true);
        } else {
            setIsBottomFixed(false);
        }
    };


    const updateScrollHeight = () => {
        setFullScrollHeight(document.documentElement.scrollHeight);
    };

    const updateViewPortHeight = () => {
        setViewportHeight(window.innerHeight);
        //console.log('viewportHeight: ', viewportHeight)
        //console.log('window.innerHeight: ', window.innerHeight)
    };

    const updateScrollRate = () => {
        setScrollRate(-(viewportHeight - 664) / (viewportHeight - 2049))
        //setScrollRate(-1)
        //console.log('scrollRate: ', scrollRate)
    }

    const updateFramer2 = () => {
        setFramer2(464 / fullScrollHeight)
    }


    useEffect(() => {


        const handleResize = () => {
            updateScrollHeight();
            updateViewPortHeight();
            updateScrollRate();
            updateFramer2();
            updateLeftSideHeight();
            handleBottomFixed();
            updateMenuHeight();
        };

        const handleScroll = () => {
            handleBottomFixed();
        }


        // Add event listeners
        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        // Initial updates
        handleResize();

        return () => {
            // Clean up event listeners
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };

    }, [viewportHeight, scrollRate, fullScrollHeight, showNavbar, leftSideHeight, menuHeight, open, isBottomFixed]);





    /////////////// framer-motion

    const container = useRef(null)

    const { scrollYProgress } = useScroll({
        target: container,
        offset: ['start start', 'end start']
    })

    useMotionValueEvent(scrollYProgress, "change", (latest) => {
        //console.log("Page scroll: ", latest)
        setScrollPercent(-1 * latest)
    })

    //const sm = useTransform(scrollYProgress, [0, framer2, 1], ['0%', '0%', `${scrollRate * 100}%`])
    const sm = useTransform(scrollYProgress, [0, framer2, 1], ['0%', '0%', `${scrollPercent * 100}%`])


    return (
        <Grid container spacing={0} marginTop={8}
            sx={{
                alignItems: isBottomFixed ? "flex-end" : "flex-start",
                transition: 'top 0.35s ease, right 0.35s ease', // Smooth transition
            }} >
            <Grid ref={leftSideRef}
                item md={leftSize} sx={{ marginRight: { xs: 4, sm: 2, md: 0 }, marginLeft: { xs: 2, sm: 2, md: 4 } }}>
                {leftContent}

            </Grid>
            <br />
            <br />
            <Grid ref={menuRef} item sm={12} md={4} spacing={2}
                //transition={{ duration: 0.35, ease: 'easeInOut' }}
                //style={{ y: isSmallScreen ? 0 : sm, transition: { duration: 0.35, ease: 'easeInOut' } }}
                sx={{
                    marginRight: { sm: 0, md: 6 },
                    marginTop: { sm: 10, md: 0 },
                    position: isSmallScreen ? 'relative' : isBottomFixed ? 'absolute' : isSticky ? 'fixed' : 'absolute',
                    top: isSmallScreen || isBottomFixed ? 'auto' : isSticky ? stickyHeight : 'auto',
                    right: isSmallScreen ? 'auto' : isSticky ? '0px' : '0px',
                    //margin: isSticky ? '1rem' : '0',
                    zIndex: 2, // Ensures HoverCard is above other content
                    transition: 'top 0.35s ease, right 0.35s ease', // Smooth transition
                }}>
                <motion.div style={{ y: !isSmallScreen && open ? sm : 0 }}
                    transition={{ duration: 0.5, ease: 'easeInOut' }}
                >
                    <Grid container spacing={2} >  {/*  */}
                        <Grid item xs={12} sm={12} lg={12}>
                            <a href='../' style={{ textDecoration: 'none' }}>
                                <LearnMoreButton width={{ xs: 300, sm: 400, md: 240, lg: 400 }}
                                    text='Home'
                                    descr={(isMediumScreen && !isSmallScreen) || isXSmallScreen ? '' : 'Discover more on our Home Page'}
                                    icon={<HomeIcon />}
                                />
                            </a>
                            {/* <LearnMoreMediaCard width={{ xs: 300, sm:400, md: 350, lg: 400}} text={["Home"]} keyName="4"
                                descr="Discover more on our Home Page"
                                buttonText='VISIT HOME'
                                icon={<HelpIcon />}
                                linkClick='https://www.thenewsouthbank.com/' /> */}
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12}>
                            <a href='../prequalify' style={{ textDecoration: 'none' }}>
                                <LearnMoreButton width={{ xs: 300, sm: 400, md: 240, lg: 400 }}
                                    text='Prequalify'
                                    descr={(isMediumScreen && !isSmallScreen) || isXSmallScreen ? '' : 'Get started in under 5 minutes'}
                                    icon={<AddTaskIcon />}
                                />
                            </a>
                            {/* <LearnMoreMediaCard width={{ xs: 300, sm:400, md: 350, lg: 400}} text={["Home"]} keyName="4"
                                descr="Discover more on our Home Page"
                                buttonText='VISIT HOME'
                                icon={<HelpIcon />}
                                linkClick='https://www.thenewsouthbank.com/' /> */}
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12}>
                            <a href='../faq' style={{ textDecoration: 'none' }}>
                                <LearnMoreButton width={{ xs: 300, sm: 400, md: 240, lg: 400 }}
                                    text='FAQs'
                                    descr={(isMediumScreen && !isSmallScreen) || isXSmallScreen ? '' : 'Get answers to your SBA 7(a) questions'}
                                    icon={<HelpIcon />}
                                />
                                </a>
                                {/* <LearnMoreMediaCard width={{ xs: 300, sm: 400, md: 350, lg: 400 }} text={["Get Prequalified"]} keyName="4"
                                    descr="Get started with a 5-min prequalification"
                                    buttonText='GET PREQUALIFIED'
                                    icon={<HelpIcon />}
                                    linkClick='https://www.thenewsouthbank.com/prequalify' /> */}
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12}>
                            <a href='../learning' style={{ textDecoration: 'none' }}>
                                <LearnMoreButton width={{ xs: 300, sm: 400, md: 240, lg: 400 }}
                                    text='Learning Center'
                                    descr={(isMediumScreen && !isSmallScreen) || isXSmallScreen ? '' : 'Expand Your Knowledge'}
                                    icon={<SchoolIcon />}
                                />
                            </a>
                            {/* <LearnMoreMediaCard width={{ xs: 300, sm: 400, md: 350, lg: 400 }} text={["FAQs"]} keyName="4"
                                    descr="Get answers to SBA 7(a) frequently asked questions"
                                    buttonText='GET ANSWERS'
                                    icon={<HelpIcon />} /> */}
                        </Grid>
                        {otherLoanUses ?
                            <Grid item xs={12} sm={12} lg={12} marginBottom={2}>
                                <LearnMoreButtonDropDown width={{ xs: 300, sm: 400, md: 240, lg: 400 }}
                                    text='Explore Other Loan Uses'
                                    icon={<SearchIcon />}
                                    open={open}
                                    setOpen={setOpen}
                                />
                                {/* <LearnMoreMediaCard width={{ xs: 300, sm: 400, md: 350, lg: 400 }} text={["Learning Center"]} keyName="5"
                                    descr="Learn more with resources from our Learning Center"
                                    buttonText='LEARN MORE'
                                    icon={<SchoolIcon />} /> */}
                            </Grid>
                            : <></>
                        }
                    </Grid>
                </motion.div>
            </Grid>
        </Grid>
    )
}