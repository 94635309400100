import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import topimage from '../images/faq.png'
import topimage2 from '../images/faq2.png'
import { alpha } from '@mui/material/styles';
import { Typography, Grid, Box, Stack, useMediaQuery, Card, Button } from '@mui/material';
import StickySideBottomOnly from './StickySideBottomOnly';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import faqimage from '../images/faqpic.png'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import FastForwardIcon from '@mui/icons-material/FastForward';
import WavingHandIcon from '@mui/icons-material/WavingHand';
import { useNavigate } from 'react-router-dom';




export default function FAQ({ navBarHeight, footerHeight, open, setOpen, showNavbar }) {
    const theme = useTheme();

    const navigate = useNavigate()

    const handleClick = () => {
        navigate('/prequalify')
    };

    const [expanded, setExpanded] = useState({
        0: true, // Outer Accordion 1
        1: true, // Outer Accordion 2
        2: true, // Outer Accordion 3
        3: true, // Outer Accordion 4
        4: true, // Outer Accordion 5
        5: true, // Outer Accordion 6
        /* 6: true, // Outer Accordion 7 */
    });

    const [innerExpanded, setInnerExpanded] = useState([
        /* [false, false], // Inner Accordion states for Outer Accordion 1 */
        [false, false, false, false], // Inner Accordion states for Outer Accordion 2
        [false, false, false, false, false], // Inner Accordion states for Outer Accordion 3
        [false, false, false, false], // Inner Accordion states for Outer Accordion 4
        [false, false, false, false, false, false, false], // Inner Accordion states for Outer Accordion 5
        [false, false, false, false], // Inner Accordion states for Outer Accordion 5
        [false], // Inner Accordion states for Outer Accordion 7
    ]);

    const faqs = [
        /* {
            title: "Questions About New South Bank",
            inner: [
                {
                    question: "What sets us apart as a lender?",
                    answer: (
                        <ol>
                            <li><b>Convenience at Your Fingertips:</b> We offer an easy-to-use, 24/7 online application process.</li>
                            <li><b>Transparency Every Step of the Way:</b> No hidden fees or confusing terms.</li>
                            <li><b>Best-in-Class Service:</b> Personalized support from SBA loan experts.</li>
                            <li><b>Streamlined Approval Process:</b> Get funded faster with fewer obstacles.</li>
                            <li><b>Tailored Solutions:</b> Flexible loan options to meet your specific needs.</li>
                        </ol>
                    )
                },
                {
                    question: "Can I apply for a loan anytime?",
                    answer: <span>
                        Yes, you can apply for an SBA 7(a) loan with us anytime, day or night! Our fully online application process is available 24/7, so you can get started whenever it's convenient for you—whether that's during business hours, after your workday ends, or even on the weekend. <b>There's no need to wait for an office to open or schedule a meeting.</b>
                        <br />
                        <br />
                        Our user-friendly platform allows you to:
                        <br />
                        <ul>
                            <li>Submit your application at your own pace.</li>
                            <li>Upload required documents from the comfort of your home or office.</li>
                        </ul>
                        <br />
                        We understand that small business owners have busy schedules, so we’ve designed a process that works around your time, not the other way around.
                    </span>
                },
            ]
        }, */
        {
            title: "General Questions About SBA 7(a) Loans",
            inner: [
                {
                    question: "What is an SBA 7(a) loan?",
                    answer: <span>
                        An SBA 7(a) loan is the most popular financing option provided by the U.S. Small Business Administration (SBA). It is designed to help small businesses secure funding by offering loans partially guaranteed by the SBA. This guarantee reduces the risk for lenders, making it easier for small businesses to obtain the financing they need.
                        <br />
                        <br />
                        For more information, please visit our <a href='./sbaexplained'>SBA 7(a) Explained</a> page.
                    </span>
                },
                {
                    question: "How does the SBA 7(a) loan program work?",
                    answer: <span>
                        The SBA 7(a) loan program involves a partnership between the SBA and approved lenders, like us. We provide the loan funds, while the SBA guarantees a portion of the loan—typically up to 75%. This guarantee allows us to offer you favorable terms, such as:
                        <ul>
                            <li>Lower interest rates</li>
                            <li>Longer repayment periods</li>
                            <li>Flexible loan terms to meet your business needs</li>
                        </ul>
                        <br />
                        For more information, please visit our <a href='./sbaexplained'>SBA 7(a) Explained</a> page.
                    </span>
                },
                {
                    question: "What are the main benefits of an SBA 7(a) loan?",
                    answer: (
                        <span>
                            The SBA 7(a) loan offers numerous advantages that make it an attractive option for small business owners. Here are the key benefits:
                            <ul>
                                <li><b>Low Interest Rates:</b> SBA 7(a) loans typically feature lower interest rates than conventional and online business loans, making them more affordable for businesses seeking long-term financial solutions.</li>
                                <li><b>Longer Repayment Terms:</b> These loans come with extended repayment periods, reducing the burden of monthly payments. For example, you can enjoy up to 10 years for working capital loans and up to 25 years for real estate loans.</li>
                                <li><b>Flexible Use of Funds:</b> SBA 7(a) loans offer versatility, allowing you to use the funds for various business needs such as working capital, equipment purchases, real estate acquisition, debt refinancing, or expanding your business.</li>
                                <li><b>Smaller Down Payment Requirements:</b> Compared to other financing options, SBA 7(a) loans generally require lower down payments, helping you preserve more capital for other business needs.</li>
                                <li><b>Lower Collateral Requirements:</b> Unlike many conventional loans, SBA 7(a) loans don’t always require full collateral. Even if your business has limited collateral, it can still qualify for the necessary funds as long as it demonstrates strong cash flow and meets other eligibility criteria.</li>
                                <li><b>Access for Less Established Businesses:</b> Thanks to the SBA guarantee, we can approve loans for businesses that might not qualify for conventional loans due to limited collateral or lower credit scores.</li>
                            </ul>
                            <br />
                            For more information, please visit our <a href='./sbaexplained'>SBA 7(a) Explained</a> page.
                        </span>
                    )
                },
                {
                    question: "What can SBA 7(a) loans be used for?",
                    answer: (
                        <span>
                            SBA 7(a) loans offer flexibility for a wide range of business needs. Explore how they can help your business:
                            <ul>
                                <a href='./loanuses/acquisition'>Business Acquisition</a> – Secure financing to purchase an existing business.
                                <br />
                                <a href='./loanuses/expansion'>Expansion and Growth</a> – Fund the growth of your business or new projects.
                                <br />
                                <a href='./loanuses/construction'>Building Construction</a> – Finance new construction or facility upgrades.
                                <br />
                                <a href='./loanuses/refinance'>Debt Refinancing</a> – Refinance high-interest or existing debt for better terms.
                                <br />
                                <a href='./loanuses/equipment'>Equipment & Machinery</a> – Purchase or upgrade essential business equipment.
                                <br />
                                <a href='./loanuses/esop'>Employee Stock Ownership Plan (ESOP)</a> – Facilitate a smooth retirement by transitioning ownership to your employees.
                                <br />
                                <a href='./loanuses/partnerbuyout'>Partner Buyout</a> – Buy out a partner’s ownership in the business.
                                <br />
                                <a href='./loanuses/realestate'>Real Estate Purchase</a> – Buy commercial property for your business.
                                <br />
                                <a href='./loanuses/startup'>Start-Up Costs</a> – Get the capital needed to launch a new business.
                                <br />
                                <a href='./loanuses/workingcapital'>Working Capital </a> – Fund everyday operations and improve cash flow.
                            </ul>
                            <br />
                            For more information, please visit our <a href='./sbaexplained'>SBA 7(a) Explained</a> page.
                        </span>
                    )
                }
            ]
        },
        {
            title: "Eligibility and Qualifications",
            inner: [
                {
                    question: "Who is eligible for an SBA 7(a) loan?",
                    answer: <span>There are several factors that determine eligibility for an SBA 7(a) loan. For a quick overview of the main requirements, visit the <a href='./'>Am I Eligible</a> section of our homepage.</span>
                },
                {
                    question: "Are startups eligible for SBA 7(a) loans?",
                    answer: <span>Yes, SBA 7(a) loans are a great option for starting a business. While startups must meet certain requirements, such as having a solid business plan and good personal credit, this loan program can provide the capital needed to launch your venture. For more details, <a href='./'>visit our startup page</a>.</span>
                },
                {
                    question: "What types of businesses are not eligible for SBA 7(a) loans?",
                    answer: <ul>
                        <li>Businesses involved in illegal activities or those that operate in illegal industries</li>
                        <li>Speculative businesses, such as real estate investment firms that only buy and sell real estate</li>
                        <li>Businesses involved in lending activities, such as banks or finance companies</li>
                        <li>Businesses primarily engaged in gambling or related activities</li>
                        <li>Any business dealing in adult entertainment</li>
                        <li>Businesses involved in the cannabis industry, even if legal at the state level</li>
                        <li>Pyramid sales distribution plans</li>
                        <li>Non-profit organizations</li>
                    </ul>
                },
                {
                    question: "What credit score do I need to qualify for an SBA 7(a) loan?",
                    answer: <span>There is no official minimum credit score requirement for an SBA 7(a) loan. However, most of our borrowers tend to have very good personal credit, typically with scores of 700 or higher.
                        <br />
                        <br />
                        If your credit score is lower, we may still be able to work with you. In such cases, you might face higher interest rates or need to meet additional criteria to secure financing.</span>
                },
                {
                    question: "What are the collateral requirements for an SBA 7(a) loan?",
                    answer: <span>Collateral is typically required for SBA 7(a) loans. The SBA mandates that businesses pledge available collateral to the extent possible, including real estate, equipment, and other assets. However, the loan does not need to be fully collateralized to qualify. Our primary focus is on your ability to repay the loan, making the SBA 7(a) loan a flexible option even if you don't have sufficient collateral.</span>
                },
            ]
        },
        {
            title: "Application Process",
            inner: [
                {
                    question: "How do I apply for an SBA 7(a) loan?",
                    answer: <span>Applying for an SBA 7(a) loan is straightforward with us. Here's how the process works:
                        <ul>
                            <li><b>Prequalification:</b> Start by completing our quick prequalification form, where you’ll receive personalized loan terms and estimated rates in minutes.</li>
                            <li><b>Submit Documentation:</b> Once prequalified, you’ll need to provide necessary financial and business documents, such as tax returns, business plans, and financial statements.</li>
                            <li><b>Review & Approval:</b> We’ll review your application and submit it for approval. This includes an underwriting process to assess your eligibility and business viability.</li>
                            <li><b>Funding:</b> After approval, loan terms are finalized, and funds are disbursed to your business.</li>
                        </ul>
                        Visit our <a href='./prequalify'>prequalification</a> page to get started!
                    </span>
                },
                {
                    question: "What documents are needed to apply for an SBA 7(a) loan?",
                    answer: <span>The major documents required for an SBA 7(a) loan depend on the loan type:
                        <ul>
                            <li><b>Standard SBA 7(a) Loan:</b>
                                <ul>
                                    <li>3 years of business and personal tax returns</li>
                                    <li>Current financial statements (profit and loss statement, balance sheet)</li>
                                    <li>Personal financial statements for all 20% or more owners</li>
                                    <li>Business debt schedule</li>
                                </ul>
                            </li>
                            <li><b>Express Lane SBA 7(a) Loan:</b>
                                <ul>
                                    <li>Most recent business tax return</li>
                                    <li>Current financial statements</li>
                                </ul>
                            </li>
                        </ul>
                        These are the primary documents, but this is not an exhaustive list. Additional documentation may be needed based on your specific situation. Visit our <a href='./prequalify'>prequalification</a> page for more details and to begin the process.
                    </span>
                },
                {
                    question: "How long does it take to get approved for an SBA 7(a) loan?",
                    answer: <span>The approval time for an SBA 7(a) loan depends on the type of loan:
                        <ul>
                            <li><b>Standard SBA 7(a) Loan:</b> Typically, approval takes around <b>45 days</b>, but we can help expedite it if all required documents are ready.</li>
                            <li><b>Express SBA 7(a) Loan:</b> Approval is much faster, usually within <b>10 days</b>, due to the streamlined application process.</li>
                        </ul>
                    </span>
                },
                {
                    question: "How do you determine the loan amount I qualify for?",
                    answer: <span>The loan amount you qualify for is primarily based on your business’s recurring cash flow, as this reflects your ability to make consistent loan payments. We also take into account other factors such as your credit history, existing debt levels, and available collateral. However, strong cash flow is the most critical factor in determining the loan size.</span>
                },
            ]
        },
        {
            title: "Terms, Interest Rates, and Fees",
            inner: [
                {
                    question: "What will my interest rate be?",
                    answer: <span>Interest rates for SBA 7(a) loans vary depending on factors such as the loan use and your specific qualifications as a borrower. Our focus is on offering competitive terms upfront, ensuring you have a clear understanding of the rates and conditions. This transparency helps us work together toward your business goals.
                        <br />
                        <br />
                        We primarily offer variable rate 7(a) loans, which are tied to the prime rate and adjust quarterly.
                        <br />
                        <br />
                        To see the terms, including interest rates tailored to your business, visit our <a href='./prequalify'>prequalification</a> page for a personalized estimate.
                    </span>
                },
                {
                    question: "How long are the repayment terms for SBA 7(a) loans?",
                    answer: <span>Repayment terms for SBA 7(a) loans depend on the loan’s purpose:
                        <ul>
                            <b>Up to 10 years</b> for working capital, equipment, and general business purposes.
                            <br />
                            <b>Up to 25 years</b> for real estate purchases.
                        </ul>
                    </span>
                },
                {
                    question: "Are there any fees associated with SBA 7(a) loans?",
                    answer: <span>Yes, there are fees associated with SBA 7(a) loans. Below are the two most common types of fees, which we allow to be financed as part of your loan:
                        <br />
                        <br />
                        SBA Guaranty Fees:
                        <ul>
                            <b>Loans of $1,000,000 or less:</b> The SBA has <u><b>waived</b></u> the upfront guaranty fee!
                            <br />
                            <b>Loans over $1,000,000:</b> The guaranty fee structure can be confusing, so we offer a simple <a href='./calculator/guaranteefee'>guaranty fee calculator</a> to help you quickly determine the fees for loans over $1 million.
                        </ul>
                        Packaging Fees:
                        <ul>
                            Standard SBA 7(a) Loan: $2,500
                            <br />
                            Express Lane 7(a): $500
                        </ul>
                        Additional closing costs may include fees for legal services, appraisals, business valuations, and other loan-related expenses.
                        <br />
                        <br />
                        As part of our <a href='./prequalify'>prequalification</a> process, we provide a clear outline of the expected fees for your specific loan.
                    </span>
                },
                {
                    question: "Can SBA 7(a) loans be used to refinance existing debt?",
                    answer: <span>Yes, SBA 7(a) loans can be used to refinance existing debt. For more details on how this works, visit our <a href='./loanuses/refinance'>Debt Refinance</a> page.</span>
                },
                {
                    question: "Can I use home equity for my required equity injection?",
                    answer: <span>Yes, you can use funds obtained from a home equity loan or home equity line of credit (HELOC) for your required equity injection in an SBA 7(a) loan. When using home equity we will evaluate your ability to repay both the SBA loan and the home equity loan.</span>
                },
                {
                    question: "Can I use retirement funds for my required equity injection?",
                    answer: <span>Yes, it is possible to use retirement funds for your required equity injection in an SBA 7(a) loan. This process is often done through a Rollover for Business Startups (ROBS), which allows you to invest your retirement funds into your business without incurring early withdrawal penalties or taxes. Our team will ensure that all SBA rules and regulations are followed to help you use these funds effectively while complying with the relevant guidelines.</span>
                },
                {
                    question: "What are the personal guaranty requirements?",
                    answer: <span>Under SBA rules, <b>a personal guaranty is required</b> from anyone who owns 20% or more of the business.
                        <br />
                        <br />
                        For non-owner spouses, if they jointly own assets with the borrower, such as a home used as collateral, they may also be required to sign a limited personal guaranty.
                    </span>
                },
            ]
        },
        {
            title: "Post-Approval and Repayment",
            inner: [
                {
                    question: "What happens after my SBA 7(a) loan is approved?",
                    answer: <span>Once your SBA 7(a) loan is approved, the process typically follows these steps:
                        <ol>
                            <li><b>Loan Agreement:</b> You'll review and sign the loan agreement, which outlines all the terms and conditions of the loan.</li>
                            <li><b>Closing Process:</b> During closing, we ensure all final paperwork is completed, including any necessary legal documents, updated financials, and proof of insurance. If collateral is involved, appraisals or title work may be finalized at this stage.</li>
                            <li><b>Loan Disbursement:</b> After closing, the funds will be disbursed according to the terms of your loan, either in a lump sum or through scheduled draws, depending on the loan’s purpose.</li>
                            <li><b>Repayment Schedule:</b> You'll receive a detailed repayment schedule, outlining when and how to make your payments.</li>
                        </ol>
                        We’ll guide you through each step of the closing process and beyond, ensuring everything is clear and seamless.
                    </span>
                },
                {
                    question: "How do I make payments on my SBA 7(a) loan?",
                    answer: <span>Making payments on your SBA 7(a) loan is simple—you’ll make all payments directly to us. We offer several convenient options, including online payment portals, automatic debit from your bank account, and traditional methods like checks. We'll provide detailed instructions and your payment schedule when your loan is finalized, ensuring the process is as straightforward as possible.</span>
                },
                {
                    question: "Can I pay off my SBA 7(a) loan early?",
                    answer: <span>Yes, you can pay off your SBA 7(a) loan early. Here’s how it works depending on your loan term:
                        <br />
                        <br />
                        <b>Loans with Maturities of Under 15 Years</b>
                        <br />
                        You can pay off these loans at any time without a prepayment penalty.
                        <br />
                        <br />
                        <b>Loans with Maturities of 15 Years or More</b>
                        <br />
                        A prepayment penalty applies if you pay off more than 25% of the loan within the first three years:
                        <ul>
                            <b>5%</b> in the first year
                            <br />
                            <b>3%</b> in the second year
                            <br />
                            <b>1%</b> in the third year
                        </ul>
                        After the third year, you can pay off the loan with no penalties.
                    </span>
                },
                {
                    question: "What happens if I miss a loan payment?",
                    answer: <span>We charge a late fee of 5% of the missed loan payment if the payment is more than 10 days delinquent.</span>
                },
            ]
        },
        {
            title: "Additional Support and Resources",
            inner: [
                {
                    question: "Do you offer assistance in preparing my loan application?",
                    answer: <span>Absolutely! We make it easy to get started at your convenience, providing all the information you need on your schedule. Additionally, our team is available to assist you throughout the entire process, answering any questions and guiding you step-by-step to ensure your application is complete and accurate.
                        <br />
                        <br />
                        <a href='./contact'>Contact Us</a>
                    </span>
                },
            ]
        },
    ];


    const backgroundColors = [
    /* theme.palette.primary.faqshade1, */
    theme.palette.primary.faqshade2,
    theme.palette.primary.faqshade3,
    theme.palette.primary.faqshade1,
    theme.palette.primary.faqshade2,
    theme.palette.primary.faqshade3,
    theme.palette.primary.faqshade1];


    const handleOuterChange = (outerIndex) => (event, isExpanded) => {
        setExpanded({ ...expanded, [outerIndex]: isExpanded });
    };

    const handleInnerChange = (outerIndex, innerIndex) => () => {
        const updatedInnerExpanded = innerExpanded.map((innerArray, idx) =>
            idx === outerIndex
                ? innerArray.map((isExpanded, innerIdx) =>
                    innerIdx === innerIndex ? !isExpanded : isExpanded
                )
                : innerArray
        );
        setInnerExpanded(updatedInnerExpanded);
    };

    const LeftContent = () => (
        <div>
            <Grid container justifyContent="center" alignItems="center" >
                <Grid item xs={12} md={10} marginTop={5} marginBottom={2}>
                    <Typography sx={{
                        color: theme.palette.primary.dark,
                        fontWeight: 500, // Adjust font weight as needed
                        textAlign: 'center',
                        //marginLeft: 3,
                        paddingTop: 3,
                        fontSize: { xs: 30, sm: 38, md: 45 },
                    }}>
                        {isLargeScreen ? <span>Got Questions? We've Got Answers</span>
                            : <span>Got Questions?<br />We've Got Answers</span>
                        }
                    </Typography>
                    {/* <Typography sx={{
                    color: theme.palette.primary.dark,
                    fontWeight: 600, // Adjust font weight as needed
                    textAlign: 'center',
                    marginLeft: 3,
                    fontSize: { xs: 30, sm: 38, md: 45 },
                }}>
                    We’ve Got Answers
                </Typography> */}
                    <br />
                </Grid>
                <br />
                <Grid item xs={11} sm={10} md={8} lg={7} margin={2} >
                    {faqs.map((faq, outerIndex) => (
                        <Box key={outerIndex} mb={8}> {/* Add margin-bottom to the Box */}
                            <Typography sx={{
                                color: 'black',
                                fontWeight: 600, // Adjust font weight as needed
                                textAlign: 'center',
                                marginLeft: 3,
                                fontSize: { xs: 20, sm: 25, md: 30 },
                            }}>
                                {faq.title}
                            </Typography>
                            {faq.inner.map((innerItem, innerIndex) => (
                                <Accordion key={innerIndex} expanded={innerExpanded[outerIndex][innerIndex]} onChange={handleInnerChange(outerIndex, innerIndex)}
                                    sx={{ margin: 1, boxShadow: 'none', border: 1, borderColor: theme.palette.primary.faqshade3 }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel${outerIndex}-${innerIndex}-content`}
                                        id={`panel${outerIndex}-${innerIndex}-header`}
                                        sx={{
                                            fontWeight: innerExpanded[outerIndex][innerIndex] ? 'bold' : 'normal', // Bold when inner is expanded
                                            fontSize: { xs: 17, sm: 19, md: 20, lg: 20 }
                                        }}
                                    >
                                        {innerItem.question}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography sx={{ fontSize: { xs: 16, sm: 18, md: 19, lg: 19 } }}>{innerItem.answer}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Box>
                    ))}
                </Grid>
            </Grid>




        </div>

    )


    const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const divMarginTop = isSmallScreen ? '90px' : '100px'

    console.log(isMediumScreen)

    return (
        <div style={{ marginTop: '0rem' }}>
            <div id="image-container" style={{
                marginTop: divMarginTop,
                width: '100%',

            }}>
                <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: alpha(theme.palette.primary.dark, 0.9)
                    }}
                >
                    <Typography
                        component="span"
                        sx={{
                            color: 'white',
                            fontWeight: 600, // Adjust font weight as needed
                            textAlign: 'center',
                            marginLeft: 3,
                            padding: 5,
                            fontSize: { xs: 30, sm: 40, md: 50 },
                            lineHeight: 1.1
                        }}
                    >
                        FREQUENTLY ASKED QUESTIONS
                    </Typography>
                </Stack>
            </div>

            <Card
                style={{
                    color: 'white',
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    padding: 0,
                    marginTop: 0,
                    marginBottom: '-25px',
                    height: '100%',
                    borderRadius: 0,
                    position: 'relative', // Ensure relative positioning for absolute children
                    overflow: 'hidden', // Clip overflowing content (like the image)
                }}
            >
                <div style={{ position: 'relative', zIndex: 1, textAlign: 'center', padding: '20px' }}>
                    <Card
                        sx={{
                            boxShadow: 'none',
                            bgcolor: 'transparent',
                            height: '100%' // Ensure the card takes up full height
                        }}
                    >
                        <Stack
                            direction={{ xs: 'column', md: 'row' }}
                            justifyContent="center"
                            alignItems="stretch" // Ensure items stretch to equal height
                            spacing={0}
                            sx={{ margin: { xs: 0, md: 2 } }}
                        >
                            <Grid container spacing={2} justifyContent="center" alignItems="stretch">
                                <Grid item xs={12} sm={12} md={6} lg={6}
                                    sx={{
                                        display: 'flex', flexDirection: 'column', height: '100%',
                                        order: isMediumScreen ? 2 : 1,
                                        marginRight: {xs: 0, md: 0, lg: 5 },
                                        marginLeft: {xs: 0, md: -2 }
                                    }}>
                                    <Stack
                                        direction="column"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        spacing={2}
                                        paddingY={2}
                                        sx={{ paddingX: {xs: 0, sm: 4, md: 4, lg: 4} }}
                                    >
                                        <Stack
                                            direction="column"
                                            spacing={0.5}
                                            width="100%"
                                            sx={{ display: 'flex' }}
                                        >
                                            <Stack direction="row" spacing={1} sx={{ justifyContent: "center", alignItems: "center", }}>
                                                <Typography sx={{
                                                    fontSize: { xs: 20, sm: 20, md: 22, lg: 24 }, color: 'primary.dark', fontWeight: 600
                                                }}>
                                                    Why Choose CapTec?
                                                </Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={1} sx={{ justifyContent: "center", alignItems: "center", paddingBottom: 2 }}>
                                                <Typography sx={{
                                                    fontSize: { xs: 18, sm: 18, md: 20, lg: 20 }, color: 'secondary.light', fontWeight: 500, lineHeight: 1.2
                                                }}>
                                                    We specialize in providing customized SBA 7(a) loans with competitive rates, flexible terms, and a seamless process designed for small business owners.
                                                </Typography>
                                            </Stack>
                                        </Stack>

                                        <Stack
                                            direction="column"
                                            spacing={0.5}
                                            width="100%"
                                            sx={{ display: 'flex' }}
                                        >
                                            <Stack direction="row" spacing={1} sx={{ justifyContent: "flex-start", alignItems: "center", }}>
                                                <AccessTimeIcon sx={{ color: theme => theme.palette.primary.main }} />
                                                <Typography sx={{ fontSize: { xs: 16, sm: 16, md: 16, lg: 18 }, color: 'secondary.light', fontWeight: 600
                                                    , textAlign: 'left' }}>
                                                    Less than 5 minutes
                                                </Typography>
                                            </Stack>
                                            <Typography sx={{
                                                fontSize: { xs: 16, sm: 16, md: 16, lg: 16 }, color: 'secondary.light', fontWeight: 500, lineHeight: 1
                                                , paddingLeft: 6, textAlign: 'left'
                                            }}>
                                                Get instant results 24/7 with our online prequalification.
                                            </Typography>
                                        </Stack>
                                        <Stack
                                            direction="column"
                                            spacing={0.5}
                                            width="100%"
                                            sx={{ display: 'flex' }}
                                        >
                                            <Stack direction="row" spacing={1} sx={{ justifyContent: "flex-start", alignItems: "center", }}>
                                                <LockOpenIcon sx={{ color: theme => theme.palette.primary.main }} />
                                                <Typography sx={{ fontSize: { xs: 16, sm: 16, md: 16, lg: 18 }, color: 'secondary.light', fontWeight: 600
                                                    , lineHeight: 1, textAlign: 'left' }}>
                                                    Know your interest rate and payment estimate
                                                </Typography>
                                            </Stack>
                                            <Typography sx={{
                                                fontSize: { xs: 16, sm: 16, md: 16, lg: 16 }, color: 'secondary.light', fontWeight: 500, lineHeight: 1
                                                , paddingLeft: 6, textAlign: 'left'
                                            }}>
                                                Instantly receive a personalized loan estimate with clear details on rates, terms, fees, and funding timelines. No delays. No hidden costs.
                                            </Typography>
                                        </Stack>
                                        <Stack
                                            direction="column"
                                            spacing={0.5}
                                            width="100%"
                                            sx={{ display: 'flex' }}
                                        >
                                            <Stack direction="row" spacing={1} sx={{ justifyContent: "flex-start", alignItems: "center", }}>
                                                <FastForwardIcon sx={{ color: theme => theme.palette.primary.main }} />
                                                <Typography sx={{ fontSize: { xs: 16, sm: 16, md: 16, lg: 18 }, color: 'secondary.light', fontWeight: 600
                                                    , lineHeight: 1, textAlign: 'left' }}>
                                                    Efficient Process
                                                </Typography>
                                            </Stack>
                                            <Typography sx={{
                                                fontSize: { xs: 16, sm: 16, md: 16, lg: 16 }, color: 'secondary.light', fontWeight: 500, lineHeight: 1
                                                , paddingLeft: 6, textAlign: 'left'
                                            }}>
                                                Once you're prequalified, we ensure every step is handled smoothly and quickly, making the lending experience seamless.
                                            </Typography>
                                        </Stack>
                                        <Stack
                                            direction="column"
                                            spacing={0.5}
                                            width="100%"
                                            sx={{ display: 'flex' }}
                                        >
                                            <Stack direction="row" spacing={1} sx={{ justifyContent: "flex-start", alignItems: "center", }}>
                                                <WavingHandIcon sx={{ color: theme => theme.palette.primary.main }} />
                                                <Typography sx={{ fontSize: { xs: 16, sm: 16, md: 16, lg: 18 }, color: 'secondary.light', fontWeight: 600
                                                    , lineHeight: 1, textAlign: 'left' }}>
                                                    Expert Guidance
                                                </Typography>
                                            </Stack>
                                            <Typography sx={{
                                                fontSize: { xs: 16, sm: 16, md: 16, lg: 16 }, color: 'secondary.light', fontWeight: 500, lineHeight: 1
                                                , paddingLeft: 6, textAlign: 'left'
                                            }}>
                                                Receive dedicated support throughout the process, ensuring clarity and ease every step of the way.
                                            </Typography>
                                        </Stack>
                                        {/* <Stack
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            width='100%'
                                            spacing={2}
                                            paddingTop={2}
                                        >
                                            <Button onClick={handleClick}
                                                sx={{
                                                    color: 'white',
                                                    backgroundColor: theme.palette.secondary.main,
                                                    height: 35,
                                                    border: 'none',
                                                    margin: '0 8px 0 8px',
                                                    paddingRight: '8px',
                                                    //fontSize: { xs: 12, sm: 14, md: 16 },
                                                    fontWeight: 500,
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        backgroundColor: alpha(theme.palette.secondary.main, 0.5), // Lighter background color on hover
                                                    },
                                                    display: { xs: 'inline-flex', md: 'inline-flex' },
                                                    alignItems: 'center', // Align items vertically in flexbox
                                                }}>
                                                <Typography sx={{ fontSize: { xs: 18, sm: 18, md: 18, lg: 24 }, color: 'white', fontWeight: 700 }}>
                                                    Get Prequalified
                                                </Typography>
                                            </Button>
                                        </Stack> */}

                                    </Stack>
                                </Grid>

                                {!isMediumScreen &&
                                (
                                <Grid item xs={12} sm={12} md={6} lg={5} sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    //justifyContent: 'space-between', // Distribute space between elements
                                    height: '100%', // Ensure the grid item takes full height
                                    order: isMediumScreen ? 1 : 2,
                                    marginTop: {xs: 0, md: 2}
                                }}>
                                    
                                        <div style={{
                                            width: '90%',
                                            height: '300px', // Adjust the height as needed
                                            position: 'relative',
                                            overflow: 'hidden', // Ensures content does not overflow and respects rounded corners
                                            marginBottom: 'auto', // Pushes image to the bottom of the container
                                            marginTop: 50,
                                            borderRadius: '20px', // Apply border-radius here
                                        }}>

                                            <img
                                                src={faqimage}
                                                alt="Learn More"
                                                style={{
                                                    position: 'absolute',
                                                    top: '0', // Align image to the top
                                                    left: '0',
                                                    width: '100%',
                                                    height: '100%', // Make sure it covers the container
                                                    objectFit: 'cover',
                                                }}
                                            />
                                        </div>
                                        
                                </Grid>
                                )}
                            </Grid>
                        </Stack>
                    </Card>
                    <br />
                    <br />
                </div>
            </Card>

            <div style={{
                backgroundColor: theme.palette.primary.faqshade2,
                //marginTop: '-10px'
            }}>
                <LeftContent />
                <StickySideBottomOnly otherLoanUses={false} />
                {/* <StickySide isSticky={isSticky} stickyHeight={stickyHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar}
                leftContent={<LeftContent />}
                leftSize='7'
                otherLoanUses={true} /> */}

                <br />
                <br />
                <br />
                <br />
            </div>
        </div >
    );
}




