import * as React from 'react';
import Card from '@mui/material/Card';
import { CardContent, Typography, Stack, Box, Popper, ClickAwayListener, Paper, MenuList, MenuItem, Grow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';

export default function LearnMoreMediaCard({ width, open, setOpen, text, keyName, descr, linkClick, buttonText, icon }) {
    const theme = useTheme();


    const anchorRef = React.useRef(null);

    const navigate = useNavigate()

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handleNone = () => {
        console.log('none')
    };

    const expressClick = (event) => {
        navigate('/prequalify')
        handleClose(event);
    };

    const sbaClick = (event) => {
        navigate('/prequalify')
        handleClose(event);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }} >
            <div style={{ textDecoration: 'none' }}>
                <Card
                    onClick={open ? handleNone : handleToggle}
                    sx={{
                        display: 'flex', width: width, height: open ? 'auto' : 50,
                        alignItems: 'center',
                        backgroundColor: 'transparent',
                        transition: 'background-color 0.3s',
                        border: `1px solid ${theme.palette.primary.bright}`,
                        '&:hover': {
                            backgroundColor: theme.palette.secondary.contrastText,
                            boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.2)',
                            opacity: '0.8',
                            cursor: open ? '' : 'pointer',
                        },
                    }}>
                    <CardContent ref={anchorRef}
                        sx={{
                            display: 'flex',          // Use flexbox
                            flexDirection: 'column',  // Align children in a column
                            justifyContent: open ? 'flex-start' : 'center', // Center items vertically
                            alignItems: 'flex-start',     // align left
                            height: '100%',
                            width: '100%',
                            padding: 1,
                            paddingBottom: 0,
                        }} >
                        <Stack direction="column"
                            justifyContent="flext-start"
                            alignItems="flext-start"
                        >

                            <Stack
                                direction="row"
                                justifyContent="flext-start"
                                alignItems="center"
                                spacing={2}
                                marginBottom='-16px'
                                onClick={open ? handleToggle : handleNone}
                                sx={{
                                    '&:hover': {
                                        cursor: 'pointer',
                                    },
                                }}
                            >
                                {React.cloneElement(icon, { sx: { color: theme => theme.palette.primary.red } })}
                                <Typography variant="h4" fontWeight={550} 
                                    sx={{
                                        fontSize: {
                                            xs: '20px', // Adjust font size for extra-small screens (phones)
                                            sm: '20px', // Adjust font size for small screens (tablets)
                                            md: '16px', // Adjust font size for medium screens (small desktops)
                                            lg: '20px', // Adjust font size for large screens (desktops)
                                            xl: '22px', // Adjust font size for extra-large screens (large desktops and above)
                                        },
                                        color: theme.palette.primary.dark, textAlign: 'left',
                                        paddingRight: '-16px'
                                    }}>
                                    {text}
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'left', transform: 'translateX(-16px)' }}>
                                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </Box>

                                {/* <Popper
                                open={open}
                                anchorEl={anchorRef.current}
                                role={undefined}
                                placement="bottom-start"
                                transition
                                disablePortal
                            >
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin:
                                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                                        }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={handleClose}>
                                                <MenuList
                                                    autoFocusItem={open}
                                                    id="composition-menu"
                                                    aria-labelledby="composition-button"
                                                    onKeyDown={handleListKeyDown}
                                                >
                                                    <MenuItem onClick={expressClick} sx={{ fontSize: { xs: 12, sm: 14, md: 16 }, fontWeight: 'bold' }}>
                                                        Express Lane 7(a)</MenuItem>
                                                    <MenuItem onClick={sbaClick} sx={{ fontSize: { xs: 12, sm: 14, md: 16 }, fontWeight: 'bold' }}>
                                                        Standard 7(a)</MenuItem>
                                                    {/* <MenuItem onClick={calcClick}>Loan Calculator</MenuItem> 
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper> */}
                            </Stack>
                            {open ?
                                (
                                    <div style={{ marginTop: '24px', marginLeft: '56px' }}>
                                        <a href='./acquisition' style={{ textDecoration: 'none' }}>
                                            <Typography
                                                variant="h4"
                                                fontWeight={500}
                                                sx={{
                                                    fontSize: {
                                                        xs: '18px', // Adjust font size for extra-small screens (phones)
                                                        sm: '18px', // Adjust font size for small screens (tablets)
                                                        md: '16px', // Adjust font size for medium screens (small desktops)
                                                        lg: '18px', // Adjust font size for large screens (desktops)
                                                        xl: '20px', // Adjust font size for extra-large screens (large desktops and above)
                                                    },
                                                    color: theme.palette.primary.dark,
                                                    textAlign: 'left',
                                                    marginBottom: 1,
                                                    textDecoration: 'none', // No underline by default
                                                    transition: 'text-decoration 0.3s ease', // Smooth transition effect
                                                    '&:hover': {
                                                        textDecoration: `underline`, // Underline on hover
                                                        cursor: 'pointer',
                                                        textDecorationColor: theme.palette.primary.dark, // Optional: Set underline color
                                                    },
                                                }}
                                            >
                                                Business Acquisition
                                            </Typography>
                                        </a>
                                        <a href='./expansion' style={{ textDecoration: 'none' }}>
                                            <Typography
                                                variant="h4"
                                                fontWeight={500}
                                                sx={{
                                                    fontSize: {
                                                        xs: '18px', // Adjust font size for extra-small screens (phones)
                                                        sm: '18px', // Adjust font size for small screens (tablets)
                                                        md: '16px', // Adjust font size for medium screens (small desktops)
                                                        lg: '18px', // Adjust font size for large screens (desktops)
                                                        xl: '20px', // Adjust font size for extra-large screens (large desktops and above)
                                                    },
                                                    color: theme.palette.primary.dark,
                                                    textAlign: 'left',
                                                    marginBottom: 1,
                                                    textDecoration: 'none', // No underline by default
                                                    transition: 'text-decoration 0.3s ease', // Smooth transition effect
                                                    '&:hover': {
                                                        textDecoration: `underline`, // Underline on hover
                                                        cursor: 'pointer',
                                                        textDecorationColor: theme.palette.primary.dark, // Optional: Set underline color
                                                    },
                                                }}
                                            >
                                                Expansion & Growth
                                            </Typography>
                                        </a>
                                        <a href='./construction' style={{ textDecoration: 'none' }}>
                                            <Typography
                                                variant="h4"
                                                fontWeight={500}
                                                sx={{
                                                    fontSize: {
                                                        xs: '18px', // Adjust font size for extra-small screens (phones)
                                                        sm: '18px', // Adjust font size for small screens (tablets)
                                                        md: '16px', // Adjust font size for medium screens (small desktops)
                                                        lg: '18px', // Adjust font size for large screens (desktops)
                                                        xl: '20px', // Adjust font size for extra-large screens (large desktops and above)
                                                    },
                                                    color: theme.palette.primary.dark,
                                                    textAlign: 'left',
                                                    marginBottom: 1,
                                                    textDecoration: 'none', // No underline by default
                                                    transition: 'text-decoration 0.3s ease', // Smooth transition effect
                                                    '&:hover': {
                                                        textDecoration: `underline`, // Underline on hover
                                                        cursor: 'pointer',
                                                        textDecorationColor: theme.palette.primary.dark, // Optional: Set underline color
                                                    },
                                                }}
                                            >
                                                Building Construction
                                            </Typography>
                                        </a>
                                        <a href='./refinance' style={{ textDecoration: 'none' }}>
                                            <Typography
                                                variant="h4"
                                                fontWeight={500}
                                                sx={{
                                                    fontSize: {
                                                        xs: '18px', // Adjust font size for extra-small screens (phones)
                                                        sm: '18px', // Adjust font size for small screens (tablets)
                                                        md: '16px', // Adjust font size for medium screens (small desktops)
                                                        lg: '18px', // Adjust font size for large screens (desktops)
                                                        xl: '20px', // Adjust font size for extra-large screens (large desktops and above)
                                                    },
                                                    color: theme.palette.primary.dark,
                                                    textAlign: 'left',
                                                    marginBottom: 1,
                                                    textDecoration: 'none', // No underline by default
                                                    transition: 'text-decoration 0.3s ease', // Smooth transition effect
                                                    '&:hover': {
                                                        textDecoration: `underline`, // Underline on hover
                                                        cursor: 'pointer',
                                                        textDecorationColor: theme.palette.primary.dark, // Optional: Set underline color
                                                    },
                                                }}
                                            >
                                                Debt Refinance
                                            </Typography>
                                        </a>
                                        <a href='./equipment' style={{ textDecoration: 'none' }}>
                                            <Typography
                                                variant="h4"
                                                fontWeight={500}
                                                sx={{
                                                    fontSize: {
                                                        xs: '18px', // Adjust font size for extra-small screens (phones)
                                                        sm: '18px', // Adjust font size for small screens (tablets)
                                                        md: '16px', // Adjust font size for medium screens (small desktops)
                                                        lg: '18px', // Adjust font size for large screens (desktops)
                                                        xl: '20px', // Adjust font size for extra-large screens (large desktops and above)
                                                    },
                                                    color: theme.palette.primary.dark,
                                                    textAlign: 'left',
                                                    marginBottom: 1,
                                                    textDecoration: 'none', // No underline by default
                                                    transition: 'text-decoration 0.3s ease', // Smooth transition effect
                                                    '&:hover': {
                                                        textDecoration: `underline`, // Underline on hover
                                                        cursor: 'pointer',
                                                        textDecorationColor: theme.palette.primary.dark, // Optional: Set underline color
                                                    },
                                                }}
                                            >
                                                Equipment & Machinery
                                            </Typography>
                                        </a>
                                        <a href='./esop' style={{ textDecoration: 'none' }}>
                                            <Typography
                                                variant="h4"
                                                fontWeight={500}
                                                sx={{
                                                    fontSize: {
                                                        xs: '18px', // Adjust font size for extra-small screens (phones)
                                                        sm: '18px', // Adjust font size for small screens (tablets)
                                                        md: '16px', // Adjust font size for medium screens (small desktops)
                                                        lg: '18px', // Adjust font size for large screens (desktops)
                                                        xl: '20px', // Adjust font size for extra-large screens (large desktops and above)
                                                    },
                                                    color: theme.palette.primary.dark,
                                                    textAlign: 'left',
                                                    marginBottom: 1,
                                                    textDecoration: 'none', // No underline by default
                                                    transition: 'text-decoration 0.3s ease', // Smooth transition effect
                                                    '&:hover': {
                                                        textDecoration: `underline`, // Underline on hover
                                                        cursor: 'pointer',
                                                        textDecorationColor: theme.palette.primary.dark, // Optional: Set underline color
                                                    },
                                                }}
                                            >
                                                ESOP
                                            </Typography>
                                        </a>
                                        <a href='./partnerbuyout' style={{ textDecoration: 'none' }}>
                                            <Typography
                                                variant="h4"
                                                fontWeight={500}
                                                sx={{
                                                    fontSize: {
                                                        xs: '18px', // Adjust font size for extra-small screens (phones)
                                                        sm: '18px', // Adjust font size for small screens (tablets)
                                                        md: '16px', // Adjust font size for medium screens (small desktops)
                                                        lg: '18px', // Adjust font size for large screens (desktops)
                                                        xl: '20px', // Adjust font size for extra-large screens (large desktops and above)
                                                    },
                                                    color: theme.palette.primary.dark,
                                                    textAlign: 'left',
                                                    marginBottom: 1,
                                                    textDecoration: 'none', // No underline by default
                                                    transition: 'text-decoration 0.3s ease', // Smooth transition effect
                                                    '&:hover': {
                                                        textDecoration: `underline`, // Underline on hover
                                                        cursor: 'pointer',
                                                        textDecorationColor: theme.palette.primary.dark, // Optional: Set underline color
                                                    },
                                                }}
                                            >
                                                Partner Buyout
                                            </Typography>
                                        </a>
                                        <a href='./realestate' style={{ textDecoration: 'none' }}>
                                            <Typography
                                                variant="h4"
                                                fontWeight={500}
                                                sx={{
                                                    fontSize: {
                                                        xs: '18px', // Adjust font size for extra-small screens (phones)
                                                        sm: '18px', // Adjust font size for small screens (tablets)
                                                        md: '16px', // Adjust font size for medium screens (small desktops)
                                                        lg: '18px', // Adjust font size for large screens (desktops)
                                                        xl: '20px', // Adjust font size for extra-large screens (large desktops and above)
                                                    },
                                                    color: theme.palette.primary.dark,
                                                    textAlign: 'left',
                                                    marginBottom: 1,
                                                    textDecoration: 'none', // No underline by default
                                                    transition: 'text-decoration 0.3s ease', // Smooth transition effect
                                                    '&:hover': {
                                                        textDecoration: `underline`, // Underline on hover
                                                        cursor: 'pointer',
                                                        textDecorationColor: theme.palette.primary.dark, // Optional: Set underline color
                                                    },
                                                }}
                                            >
                                                Real Estate Purchase
                                            </Typography>
                                        </a>
                                        <a href='./startup' style={{ textDecoration: 'none' }}>
                                            <Typography
                                                variant="h4"
                                                fontWeight={500}
                                                sx={{
                                                    fontSize: {
                                                        xs: '18px', // Adjust font size for extra-small screens (phones)
                                                        sm: '18px', // Adjust font size for small screens (tablets)
                                                        md: '16px', // Adjust font size for medium screens (small desktops)
                                                        lg: '18px', // Adjust font size for large screens (desktops)
                                                        xl: '20px', // Adjust font size for extra-large screens (large desktops and above)
                                                    },
                                                    color: theme.palette.primary.dark,
                                                    textAlign: 'left',
                                                    marginBottom: 1,
                                                    textDecoration: 'none', // No underline by default
                                                    transition: 'text-decoration 0.3s ease', // Smooth transition effect
                                                    '&:hover': {
                                                        textDecoration: `underline`, // Underline on hover
                                                        cursor: 'pointer',
                                                        textDecorationColor: theme.palette.primary.dark, // Optional: Set underline color
                                                    },
                                                }}
                                            >
                                                Start-Up Costs
                                            </Typography>
                                        </a>
                                        <a href='./workingcapital' style={{ textDecoration: 'none' }}>
                                            <Typography
                                                variant="h4"
                                                fontWeight={500}
                                                sx={{
                                                    fontSize: {
                                                        xs: '18px', // Adjust font size for extra-small screens (phones)
                                                        sm: '18px', // Adjust font size for small screens (tablets)
                                                        md: '16px', // Adjust font size for medium screens (small desktops)
                                                        lg: '18px', // Adjust font size for large screens (desktops)
                                                        xl: '20px', // Adjust font size for extra-large screens (large desktops and above)
                                                    },
                                                    color: theme.palette.primary.dark,
                                                    textAlign: 'left',
                                                    marginBottom: 1,
                                                    textDecoration: 'none', // No underline by default
                                                    transition: 'text-decoration 0.3s ease', // Smooth transition effect
                                                    '&:hover': {
                                                        textDecoration: `underline`, // Underline on hover
                                                        cursor: 'pointer',
                                                        textDecorationColor: theme.palette.primary.dark, // Optional: Set underline color
                                                    },
                                                }}
                                            >
                                                Working Capital
                                            </Typography>
                                        </a>
                                    </div>
                                ) : <></>
                            }
                        </Stack>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
}
