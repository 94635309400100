
import learningimage from '../images/comingsoon.png'
import { Card, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import logo from '../images/capteclogo-white-bldg.png'

function ImgHomePage2(props) {
    const theme = useTheme();

    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 600);

    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth > 790);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div
  style={{
    marginTop: { xs: '80px', sm: '60px' },
    backgroundColor: theme.palette.primary.dark,
    minHeight: '100vh', // Ensures the div takes the full viewport height if needed
    display: 'flex',     // Use flex to manage the layout
    flexDirection: 'column', // Stack children vertically
  }}
>
            <Card sx={{ marginTop: {xs: 9, sm: 10, md: 0, lg: -5}, }}
                style={{
                color: 'white',
                backgroundColor: theme.palette.primary.dark,
                boxShadow: 'none',
                padding: 0,
                //marginBottom: '-25px',
                height: 'auto',
                borderRadius: 0,
                position: 'relative', // Ensure relative positioning for absolute children
                overflow: 'hidden', // Clip overflowing content (like the image)
                display: 'flex', // Use flex to ensure content fills the card
                flexDirection: 'column', // Stack children vertically
            }}>
                <img src={learningimage}
                    alt='Small Business Lending'
                    style={{
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',  // Adjust the vertical position here
                        maskImage: 'linear-gradient(to right, rgba(0,0,0,0) 1%, rgba(0,0,0,1) 100%)', // Gradient mask from transparent on the right to opaque on the left
                        WebkitMaskImage: 'linear-gradient(to right, rgba(0,0,0,0) 1%, rgba(0,0,0,1) 100%)', // For Safari
                        display: 'block'
                    }}
                >
                </img>
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: {xs: '45%', sm: '38%', md: '34%'},
                        height: '100%',
                        display: 'flex',
                        marginTop: {xs: 10, sm: 15, md: 25, lg: 30},
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        padding: {
                            xs: '0px 0px 0px 20px', // Extra small screens
                            sm: '0px 15px 0px 40px', // Small screens
                            md: '0px 0px 0px 60px', // Medium screens
                            lg: '0px 0px 0px 60px'  // Large screens
                        },
                        boxSizing: 'border-box',
                        color: 'white',
                        backgroundColor: 'transparent'
                    }}
                >
                    {/* <img src={logo}
                        alt='CapTec USA'
                        style={{
                            height: '15%',
                        }}>
                    </img> */}
                    <Typography sx={{
                        fontSize: {
                            xs: '16px', // Small size for extra small screens
                            sm: '18px', // Medium size for small screens
                            md: '22px', // Larger size for medium screens
                            lg: '30px', // Even larger size for large screens
                        },
                        /* fontWeight: {
                            lg: 700, // Bold weight for large screens
                            md: 700, // Medium weight for medium screens
                            xs: 700, // Medium weight for medium screens
                        }, */
                        
                    }}>Learning Center</Typography>
                    <Typography sx={{
                        fontSize: {
                            xs: '18px', // Small size for extra small screens
                            sm: '22px', // Medium size for small screens
                            md: '30px', // Larger size for medium screens
                            lg: '40px', // Even larger size for large screens
                        },
                        /* fontWeight: {
                            lg: 700, // Bold weight for large screens
                            md: 700, // Medium weight for medium screens
                            xs: 700, // Medium weight for medium screens
                        }, */
                        
                    }}>COMING SOON</Typography>
                    {/* <Typography sx={{
                        fontSize: {
                            xs: '14px', // Small size for extra small screens
                            sm: '16px', // Medium size for small screens
                            md: '18px', // Larger size for medium screens
                            lg: '18px', // Even larger size for large screens
                        },
                        fontWeight: {
                            lg: 500, // Bold weight for large screens
                            md: 400, // Medium weight for medium screens
                            sm: 300,
                            xs: 300
                        },
                        lineHeight: {xs: 1.2, sm: 1.5},
                        marginRight: { xs: 3, sm: 0},
                        marginTop: 1
                    }}
                    >
                        Check back soon for valuable insights and resources to help you navigate the loan process and empower your business journey.
                    </Typography> */}
                    <br/>
                </Box>
            </Card>
        </div>
    )
}

export default ImgHomePage2