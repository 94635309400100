import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import esopimage from '../images/esop6.png'
import { alpha } from '@mui/material/styles';
import { Typography, Grid, Box, Stack } from '@mui/material';
import StickySide from './StickySide';
import PrequalifyCard from './PrequalifyCard';



function interpolateColor(color1, color2, factor) {
    const result = color1.slice(1).match(/.{2}/g).map((hex, i) => {
        const num1 = parseInt(hex, 16);
        const num2 = parseInt(color2.slice(1).match(/.{2}/g)[i], 16);
        const interpolated = Math.round(num1 + factor * (num2 - num1));
        return ('0' + interpolated.toString(16)).slice(-2); // Ensure 2-digit hex
    });
    return `#${result.join('')}`;
}

function QuiltedColorList() {
    const theme = useTheme();

    const startColor = theme.palette.primary.esopstart;
    const endColor = theme.palette.primary.esopend;
    const colors = Array.from({ length: 5 }, (_, i) => interpolateColor(startColor, endColor, i / 4));

    const itemData = [
        {
            color: colors[0],
            title: 'Breakfast',
            heading: 'Smooth and Secure Ownership Transition',
            descr: 'An ESOP enables you to sell a controlling interest (51% or more) of your business to your employees, ensuring the company’s continuity. You can step back knowing that those who understand your business best will continue its operations.',
        },
        {
            color: colors[1],
            title: 'Burger',
            heading: 'No Equity Injection Required',
            descr: 'Unlike many other financing options, our SBA 7(a) ESOP loans do not require you to provide additional capital upfront, making the transition to employee ownership simpler and more accessible.',
        },
        {
            color: colors[2],
            title: 'Camera',
            heading: 'Financial Security in Retirement',
            descr: 'Selling your business through an ESOP provides you with the financial resources you need for retirement, while also offering potential tax advantages that can enhance your retirement income.',
        },
        {
            color: colors[3],
            title: 'Coffee',
            heading: 'Preserve Your Legacy',
            descr: 'With an ESOP, your business can continue to thrive under the ownership of your employees, who have a vested interest in its success. This ensures that the culture and values you’ve instilled remain intact.',
        },
        {
            color: colors[4],
            title: 'Hats',
            heading: 'Flexible Use of Funds',
            descr: 'Beyond purchasing stock, SBA 7(a) loans can be used for working capital, purchasing equipment, or other business needs, allowing your company to continue growing even as you transition out.',
        },
    ];

    return (
        <Grid
            container
            spacing={2}
            sx={{
                overflow: 'visible', // Prevent scrollbars
                width: '100%', // Take full width
                marginLeft: 1,
                paddingRight: 2,
            }}
        >
            {itemData.map((item) => (
                <Grid item key={item.title} xs={12}
                    sx={{
                        backgroundColor: item.color,
                        height: 'auto',//{ xs: '300px', sm: '210px', md: '220px', lg: '190px' },
                        marginY: 1,
                        padding: 3
                    }}>
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 600,
                        textAlign: 'left',
                        fontSize: { xs: 20, sm: 22, md: 24 },
                    }}>
                        {item.heading}
                    </Typography>
                    <Typography sx={{
                        color: theme.palette.secondary.light,
                        fontWeight: 500,
                        textAlign: 'left',
                        fontSize: { xs: 18, sm: 20, md: 20 },
                    }}>
                        {item.descr}
                    </Typography>
                </Grid>
            ))}
        </Grid>
    );
}

export default function ESOP({ navBarHeight, footerHeight, open, setOpen, showNavbar }) {
    const theme = useTheme();


    const [isSticky, setIsSticky] = useState(false);
    const [stickyHeight, setStickyHeight] = useState(0);


    const updateSticky = () => {
        const offset = document.getElementById('image-container').offsetHeight;
        setIsSticky(window.scrollY > offset);
        //setStickyHeight(menuPosition - offset)
        setStickyHeight(showNavbar ? 568 - 400 : 50);
    };



    useEffect(() => {


        const handleResize = () => {
            updateSticky();
        };

        const handleScroll = () => {
            updateSticky();
        };

        // Add event listeners
        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        // Initial updates
        handleResize();
        updateSticky();

        return () => {
            // Clean up event listeners
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };

    }, [showNavbar]);


    const LeftContent = () => (
        <div>
            <Grid item xs={12} md={11}>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 600, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    marginBottom: 2,
                    lineHeight: 1.2,
                    fontSize: { xs: 24, sm: 30, md: 40 },
                }}>
                    Secure Your Retirement<br />Preserve Your Legacy
                </Typography>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500, // Adjust font weight as needed
                    textAlign: 'left',
                    marginLeft: 3,
                    fontSize: { xs: 18, sm: 20, md: 24 }
                }}>
                    As a business owner approaching retirement, ensuring the future of your company is likely one of your top concerns. Transitioning ownership through an Employee Stock Ownership Plan (ESOP) allows you to retire with confidence, knowing your business is in capable hands. Our SBA 7(a) ESOP loans are designed to help you smoothly transition ownership while maintaining the legacy you’ve worked so hard to build.
                </Typography>
            </Grid>
            <br />
            <QuiltedColorList />
            <br />
            <br />
            <PrequalifyCard />
            <br />
            <br />
            <Grid item xs={12} md={11}  >
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 600,
                    textAlign: 'left',
                    fontSize: { xs: 20, sm: 22, md: 24 },
                    marginLeft: 3
                }}>
                    What is an ESOP?
                </Typography>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500,
                    textAlign: 'left',
                    fontSize: { xs: 18, sm: 20, md: 20 },
                    marginLeft: 3
                }}>
                    An Employee Stock Ownership Plan (ESOP) is a unique and powerful tool that allows your employees to become co-owners of the company they work for. Unlike traditional retirement plans, an ESOP is specifically designed to align the interests of employees with the long-term success of the business. Through an ESOP, employees gradually acquire shares of company stock, building their stake in the business over time.
                    <br />
                    <br />
                    For you, as a retiring business owner, an ESOP offers a way to ensure the legacy of your business is preserved while providing financial security for your retirement. By selling a controlling interest (51% or more) to an ESOP, you empower your employees to take ownership, fostering a deep sense of commitment and motivation within your team.

                </Typography>
            </Grid>
            <br />
            <br />
            <Grid item xs={12} md={11}  
                //sx={{marginLeft:{xs: 1, sm: 0} }}
                >
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 600,
                    textAlign: 'center',
                    fontSize: { xs: 22, sm: 26, md: 28 },

                }}>
                    <u>How the SBA 7(a) Loan and<br/>Stock Vesting Work</u>
                </Typography>
                <br />
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 600,
                    textAlign: 'left',
                    fontSize: { xs: 20, sm: 22, md: 24 },
                    marginLeft: 3
                }}>
                    The SBA 7(a) Loan Process:
                </Typography>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500,
                    textAlign: 'left',
                    fontSize: { xs: 18, sm: 20, md: 20 },
                    marginLeft: 3
                }}>
                    When transitioning your business to an Employee Stock Ownership Plan (ESOP), an SBA 7(a) loan can be a crucial financial tool to facilitate the purchase of company stock by the ESOP. Here's how it typically works:
                </Typography>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500,
                    textAlign: 'left',
                    fontSize: { xs: 18, sm: 20, md: 20 },
                    marginLeft: 0
                }}>
                    <ul>
                        <li><b>Loan Application and Approval</b></li>
                        <ul>
                            <li>You, as the business owner, apply for an SBA 7(a) loan specifically structured for ESOP transactions. The loan amount is typically used to finance the ESOP's purchase of a controlling interest (51% or more) in the company.</li>
                            <li>The loan terms generally include up to 25 years for loans involving commercial real estate and up to 10 years for other types of loans, with competitive interest rates and flexible repayment options.</li>
                        </ul>
                        <li><b>Funding the ESOP</b></li>
                        <ul>
                            <li>Once the loan is approved, the funds are disbursed to the ESOP, which then uses the proceeds to buy shares of the company from you, the seller. This transaction allows the ESOP to acquire the controlling interest in the business, while you receive the financial proceeds necessary for your retirement.</li>
                        </ul>
                        <li><b>Loan Repayment</b></li>
                        <ul>
                            <li>The ESOP is responsible for repaying the SBA 7(a) loan over the agreed-upon term. Repayment typically comes from the company’s profits, as the ESOP is funded by the company’s contributions, which are tax-deductible. This arrangement benefits both the company and the ESOP participants by reducing taxable income.</li>
                        </ul>
                    </ul>
                </Typography>
                <br />
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 600,
                    textAlign: 'left',
                    fontSize: { xs: 20, sm: 22, md: 24 },
                    marginLeft: 3
                }}>
                    Stock Vesting for Employees:
                </Typography>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500,
                    textAlign: 'left',
                    fontSize: { xs: 18, sm: 20, md: 20 },
                    marginLeft: 3
                }}>
                    Stock vesting refers to the process by which employees earn ownership of the stock allocated to them through the ESOP over time. Here’s how it generally works:
                </Typography>
                <Typography sx={{
                    color: theme.palette.secondary.light,
                    fontWeight: 500,
                    textAlign: 'left',
                    fontSize: { xs: 18, sm: 20, md: 20 },
                    marginLeft: 0
                }}>
                    <ul>
                        <li><b>Allocation of Shares</b></li>
                        <ul>
                            <li>Once the ESOP acquires company stock, shares are allocated to employees' ESOP accounts based on a formula, often related to their salary and tenure with the company. The more years an employee works for the company, the more shares they typically accumulate in their ESOP account.</li>
                        </ul>
                        <li><b>Vesting Schedule</b></li>
                        <ul>
                            <li>The shares allocated to employees typically vest over a period of time, known as the vesting schedule. This schedule might follow a "cliff" vesting plan, where employees become fully vested after a certain number of years, or a "graded" vesting plan, where employees gradually vest a percentage of their shares each year.</li>
                            <li>For example, in a graded vesting plan, an employee might vest 20% of their allocated shares each year over five years. After five years, they would be fully vested and own 100% of the shares allocated to them.</li>
                        </ul>
                        <li><b>Ownership and Payout</b></li>
                        <ul>
                            <li>Once vested, employees are full owners of the shares in their ESOP accounts. If they leave the company or retire, they are entitled to the value of their vested shares. The company may buy back the shares at their fair market value, providing the employee with a payout based on the value of the vested stock.</li>
                        </ul>
                        <li><b>Retirement Benefits</b></li>
                        <ul>
                            <li>The ESOP can significantly enhance employees' retirement benefits, as the value of the shares in their accounts grows over time with the company's success. This arrangement aligns employees’ interests with the long-term health of the company, fostering a stronger, more dedicated workforce.</li>
                        </ul>
                    </ul>
                </Typography>
            </Grid>
        </div>

    )




    return (
        <div style={{ marginTop: '0rem' }}>
            <div id="image-container" style={{
                position: 'relative',
                marginTop: '40px',
                width: '100%'
            }}>
                <img
                    src={esopimage}
                    alt='ESOP'
                    style={{
                        width: '100%',
                        maxHeight: '400px',
                        minHeight: '250px',
                        objectFit: 'cover',
                        objectPosition: 'center 25%',
                        display: 'block',  // Removes any extra space below the image
                    }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: { xs: '60px', sm: '80px', md: '100px' },
                        backgroundColor: alpha(theme.palette.primary.dark, 0.8),
                        zIndex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start', // Use 'flex-start' instead of 'left'
                    }}
                >
                    <Typography
                        component="span"
                        sx={{
                            color: 'white',
                            fontWeight: 600, // Adjust font weight as needed
                            textAlign: 'left',
                            marginLeft: 3,
                            fontSize: { xs: 24, sm: 34, md: 50 },
                            lineHeight: 1.1
                        }}
                    >
                        ESOP LOANS
                    </Typography>
                </Box>
            </div>
            <StickySide isSticky={isSticky} stickyHeight={stickyHeight}
                open={open} setOpen={setOpen}
                showNavbar={showNavbar}
                leftContent={<LeftContent />}
                leftSize='7'
                otherLoanUses={true} />

            <br />
            <br />
            <br />
            <br />
        </div >
    );
}




